import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { AlertService } from '../../../services/alert.service';
import { GlobalService } from '../../../services/global.service';
import { MustMatch, passwordValidator } from 'src/app/shared/_helpers/validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  parent_data: any;
  changePassForm: any = [];
  mismatch: boolean = true;
  passError: string;
  selectedLangCode: any;
  submitted = false;

  constructor(public dialogRef: MatDialogRef<ChangePasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private api: ApiService,
    private alert:AlertService,
    private global:GlobalService) {
    this.selectedLangCode = this.global.getCurrentLangCode();
    this.parent_data = this.data;
    this.changePassForm = this.fb.group({
      oldPass: ['', Validators.required],
      newPass: ['', Validators.compose([Validators.required, passwordValidator()])],
      rePass: ['', Validators.required],
    }
    ,
      {
        validator: MustMatch('newPass', 'rePass'),
      });
  }

  get f() {
    return this.changePassForm.controls;
  }

    /**
   * This is a function that used to change password
  
   * @param data data is a param
   * @returns return is void
   */
  changePassword(data) {
    this.submitted = true;

    let val = data.value;
    if (this.changePassForm.valid) {
      var body = {
        "user_id": this.api.user_id,
        "new_password": val.newPass,
        "old_password": val.oldPass
      }
      this.api.postUmsData('change-password', body).subscribe((res: any) => {
        if (res && res.status == 201) {
          this.alert.success(res.message, 5000);
          this.dialogRef.close();
        }
        else {
          this.alert.error(res.message);
          
        }

      })
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  ngOnInit(): void {
  }

}
