import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
import { Subject } from 'rxjs';

@Injectable()
export class CommonfunctionService {
  cityList;
  currentReport;
  legendCategoryColor = new Subject<any>();
  user_preference = null;

  constructor(
    private api: ApiService,
    private browser: BrowserService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    //this.checkResourceAccess('attributegroups','GET',false);
  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }

  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    arrayObj.forEach((obj) => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach((obj) => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) {
      // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach((obj) => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value) {
    value = new Date(value);
    return (
      value.getFullYear() +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getDate().toString().padStart(2, 0)
    );
  }

  formatDateUTC(dt: any, getTime: boolean = false) {
    let value;
    dt = new Date(dt);
    value = new Date(dt + ' UTC');
    if (getTime) {
      return (
        value.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
        }) +
        ' ' +
        value.getHours().toString().padStart(2, 0) +
        ':' +
        value.getMinutes().toString().padStart(2, 0) +
        ':' +
        value.getSeconds().toString().padStart(2, 0)
      );
    } else {
      return value.toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      });
    }
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj,
      k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = err.error;
    return msg.message;
  }

  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false,
    };
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    resources.forEach((element) => {
      if (element.resource_name == name) {
        if (element.methods.indexOf('GET') != -1) {
          resource.GET = true;
        }
        if (element.methods.indexOf('POST') != -1) {
          resource.POST = true;
        }
        if (element.methods.indexOf('PATCH') != -1) {
          resource.PATCH = true;
        }
        if (element.methods.indexOf('DELETE') != -1) {
          resource.DELETE = true;
        }
      }
    });
    return resource;
  }
  convertToJson(url) {
    let attr: any, val;
    let body = {};
    if (url) attr = url.split('/mi?')[1];
    if (attr) attr = attr.split('&');
    if (attr) {
      attr.forEach((e) => {
        let pair = e.split('=');
        body[pair[0]] = pair[1];
      });
      return body;
    }
  }
  replaceSpchar(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let re = /\ /gi;
      let name = value.replace(re, '_');
      return name;
    } else {
      return '';
    }
  }

  //Function used to remove querystring
  removeQString(key: any) {
    if (isPlatformBrowser(this.platformId)) {
      let urlValue = document.location.href,
        searchUrl: string,
        removeVal,
        oldValue;
      //Get query string value
      searchUrl = location.search;
      if (key.length) {
        key.map((ele) => {
          if (urlValue.includes(ele)) {
            oldValue = this.getParameterByName(ele);
            removeVal = (ele + '=' + oldValue).toString();
            if (searchUrl.indexOf('?' + removeVal + '&') != -1) {
              urlValue = urlValue.replace('?' + removeVal + '&', '?');
            } else if (searchUrl.indexOf('&' + removeVal + '&') != -1) {
              urlValue = urlValue.replace('&' + removeVal + '&', '&');
            } else if (searchUrl.indexOf('?' + removeVal) != -1) {
              urlValue = urlValue.replace('?' + removeVal, '');
            } else if (searchUrl.indexOf('&' + removeVal) != -1) {
              urlValue = urlValue.replace('&' + removeVal, '');
            }
          } else {
            return urlValue;
          }
        });
      } else {
        searchUrl = location.search;
        urlValue = urlValue.replace(searchUrl, '');
      }
      return urlValue;
      // history.pushState({ state: 1, rand: Math.random() }, '', urlValue);
    }
  }
  //Get querystring value
  getParameterByName(name) {
    if (isPlatformBrowser(this.platformId)) {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      let regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
        results = regex.exec(location.search);
      if (name == 'preference') {
        return results === null ? '' : results[1];
      } else {
        return results === null
          ? ''
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
      }
    }
  }

  //validate Email
  validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  //
  removeUnderscores(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }

  removehypenscores(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/-/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }

  camelizeAll(str) {
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }
  capitalize_first_char(str) {
    const str2 = str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      : str;
    return str2;
  }
  capitalize_each_word(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  capitalize_each_word_array(str) {
    return str
      .toLowerCase()
      .split(',')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(',');
  }

  modifyInt(options: any) {
    const { value, roundUp, convertToLocaleString, floatingPoint } = options;
    let initialValue = value;
    if (isPlatformBrowser(this.platformId)) {
      if (value === 0) initialValue = 0;
      if (roundUp === true) initialValue = Math.ceil(+initialValue);
      if (roundUp === false) initialValue = Math.round(+initialValue);
      if (floatingPoint > 0 && initialValue)
        initialValue = initialValue.toFixed(floatingPoint);
      if (convertToLocaleString === true || convertToLocaleString === undefined)
        initialValue = Number(initialValue).toLocaleString();
    }

    return initialValue;
  }

  dateDifference(start_date, end_date) {
    start_date = new Date(start_date)
    end_date =  new Date(end_date)
    return Math.floor(
      (Date.UTC(
        end_date.getFullYear(),
        end_date.getMonth(),
        end_date.getDate()
      ) -
        Date.UTC(
          start_date.getFullYear(),
          start_date.getMonth(),
          start_date.getDate()
        )) /
        (1000 * 60 * 60 * 24)
    );
  }

  datecompare(a, b, isAsc: boolean) {
    a = new Date(a.start_date).getTime();
    b = new Date(b.start_date).getTime();
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  replacedoublequote(inputString) {
    if (inputString && inputString.includes('"')) {
      return inputString.replaceAll('"', "'");
    } else {
      return inputString;
    }
  }
  escapeMetaCharacters(inputString) {
    var outputString = '';
    try {
      var metaCharacters = ['\\', "'"];
      for (var i = 0; i < metaCharacters.length; i++) {
        if (inputString.includes(metaCharacters[i])) {
          if (metaCharacters[i] == "'") {
            outputString = inputString.replace(metaCharacters[i], "''");
          } else {
            outputString = inputString.replace(
              metaCharacters[i],
              '\\' + metaCharacters[i]
            );
          }
          inputString = outputString;
        } else {
          outputString = inputString;
        }
      }
      return outputString;
    } catch (error) {
      console.log(
        `escape Meta Characters error  escapeMetaCharacters() | ${error}`
      );
    }
  }

  convertYearReport(data, column, year) {
    let report = [];

    data.forEach((value) => {
      let obj = {
        ...value,
      };

      const existData = report.some(
        (ele) =>
          ele[column.data[0]] === value[column.data[0]] &&
          ele[column.data[1]] === value[column.data[1]]
      );

      if (!existData) {
        year.forEach((year) => {
          const getDataColum = data.find(
            (ele) =>
              ele[column.data[0]] === value[column.data[0]] &&
              ele[column.data[1]] === value[column.data[1]] &&
              ele.year === year
          );
          if (getDataColum) {
            obj[`${year}`] = getDataColum.value;
            if (getDataColum?.average) {
              obj[`average${year}`] = getDataColum.average;
            }
          }
        });
        report.push(obj);
      }
    });
    return report;
  }

  convertYearReportSingle(data, column, year) {
    let report = [];

    data.forEach((value) => {
      let obj = {
        ...value,
      };
      const existData = report.some(
        (ele) => ele[column.data[0]] === value[column.data[0]]
      );
      if (!existData) {
        year.forEach((year) => {
          const getDataColum = data.find(
            (ele) =>
              ele[column.data[0]] === value[column.data[0]] && ele.year === year
          );
          obj[`${year}`] = getDataColum ? getDataColum.value : 0;
        });

        report.push(obj);
      }
    });
    return report;
  }

  getUniqueListName(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item[key]])).values()];
  }

  getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  getLegendColor(data){
    if(this.user_preference && this.user_preference.report_data){
      let legend = this.user_preference.report_data;
      return legend[data] ? legend[data] : false;
    }
    return false
  }

  setLegendColor(data) {
    let legend = {};
    if(this.user_preference && this.user_preference.report_data){
      legend = this.user_preference.report_data;
      legend[data.name] = data.color;
    } else {
      legend[data.name] = data.color;
    }
    this.user_preference['report_data'] = legend;
    this.legendCategoryColor.next(true)
    return legend;
  }
}
