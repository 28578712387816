import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModules } from '../mat-modules';
import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { I18nModule } from '../i18n/i18n.module';
import { RouterModule } from '@angular/router';
import { ShareReportComponent } from './share-report/share-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoaderModule } from '../shared/loader/loader.module';
import { SearchFilterPipe } from './pipes/search-filter.pipe';
import { NotificationsComponent } from './notifications/notifications.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TooltipDirective } from '../directive/tooltip.directive';
import { OnlyNumericeDirective } from '../directive/only-numeric.directive';

@NgModule({
    declarations: [
        FooterComponent,
        NavbarComponent,
        ShareReportComponent,
        SearchFilterPipe,
        NotificationsComponent,
        TooltipDirective,
        OnlyNumericeDirective
    ],
    imports: [
        CommonModule,
        ...MatModules,
        I18nModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        LoaderModule
    ],
    exports: [NavbarComponent, FooterComponent, SearchFilterPipe, NotificationsComponent, TooltipDirective, OnlyNumericeDirective]
})
export class CoreModule { }
