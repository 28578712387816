import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { AlertService } from './alert.service';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';
const APPLICATION_TIMEOUT_TIME = 1000 * 60 * 30; //in milliseconds
@Injectable({
  providedIn: 'root'
})
export class AutologoutService {
  public t;
 
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private api: ApiService,
    private alert:AlertService,
    private browser:BrowserService
    ) {
      if (this.api.user_id) {
        this.resetTimer();
      }
    if (isPlatformBrowser(platformId)) {
      document.body.addEventListener('click', () =>{
        this.resetTimer()
      });// catches mouse clicks
      document.body.addEventListener('keypress', () =>{ 
        this.resetTimer()
      });//catches keyboard actions
      document.body.addEventListener('mousemove', () =>{
         this.resetTimer()
        });//catches keyboard actions
      document.body.addEventListener('mousedown', () => {
        this.resetTimer()
      });//catches keyboard actions
      document.body.addEventListener('wheel', () =>{
         this.resetTimer()
        });//catches scrolling
    }
  }

  resetTimer() {
    if (isPlatformBrowser(this.platformId)) {
      clearTimeout(this.t);
      this.t = setTimeout(() => {
       if(!this.browser.getCookie('_r_m_')){
          // if remember me is not selected by user while login then autologout is executed
        this.api.logout(false);
        this.alert.info('Session Expired\nLogging out!');
       } 
      },APPLICATION_TIMEOUT_TIME);  // time is in milliseconds (1000 is 1 second)
    }
  }
}
