// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  miVersion: 'v1',
  // miUrl: 'http://51.158.98.144:2012/v1/',
  miUrl :'https://miapi.dev10.in/v1/',
  miApp:'https://mi.dev10.in/',
  miReportUrl :'https://mireportsapi.dev10.in/v1/',
  // umsUrl: 'http://51.158.98.144:2005/',
  umsUrl :"https://umsapinode.dev10.in/",
  user_token: 'TmpNPU0yTTJaVEJpT0dFNVl6RTFNakkwWVRneU1qaGlPV0U1T0dOaE1UVXpNV1E9TlRjNE1HVXdPRE00Wm1KaFpHSTRNMlF3WXpjeFpUQm1aV1ZqTmpSbU5EZz1NMk0yWlRCaU9HRTVZekUxTWpJMFlUZ3lNamhpT1dFNU9HTmhNVFV6TVdRPTE1Njk0NzgyNjE=',
  geoestater: 'https://ged.gisworld.in/',
  api_key: '00cc456ac4963d000af6c114dcf89825',
  google_key: 'AIzaSyAmnH9DzaMJXdNw2TzgZ18RydRBDIhWHUo',
  estater_meter: 'https://e2.dev10.in/estater-meter/em-overview',
  about_MI: 'https://e2.dev10.in//estater-market-overview',
  about_estater: 'https://e2.dev10.in/about-estater/estater-overview',
  em_ang: 'https://e2.dev10.in/',
  ec_url: 'https://e2.dev10.in/',
  dc_url: 'https://dc.dev10.in/',
  // emUrl: 'http://51.158.98.144:2005/',
  emUrl: 'https://apiem.dev10.in/',
  pdfYearShow: 5,
  citis: [
    {
      place_name: 'Kuwait',
      place_id: 13,
      slug:'kuwait'
    }
  ],
  max_year_filter: 10,
  'recaptchaActive': false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
