import { Component, OnInit } from '@angular/core';
import { SubjectService } from '../../services/subject.service';
@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements OnInit {
  selectedProduct = 'data';
  public profile_image;
  prodList: any = [{
    name: 'data'
  },
  {
    name: 'analytics',
  },
  {
    name: 'services',
  },
  {
    name: 'technology',
  },
  ]
  constructor(private subject: SubjectService) { 
    this.subject.profileImage.subscribe(pfImg => {
      this.profile_image = pfImg;
    });
  }
  showProduct(product) {
    this.selectedProduct = product;
  }
  ngOnInit(): void {
  }
}
