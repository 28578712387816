<!-- Footer -->
<footer class="page-footer font-small indigo text_right_ar">

    <!-- <div class="container">
            <div class="row content">
                <div class="col-md-3">
                    <div class="about_sec text_right_ar">
                        <img src="./../../../assets/MIAssets/remi_logo_white.svg" class="img-fluid" />
                        <p>
                            {{'footer.about_content1' | translate}} <br />
                            {{'footer.about_content2' | translate}} <br />
                            {{'footer.about_content3' | translate}}
                        </p>
                        <ul class="social_icon">
                            <li>
                                <a href="https://www.facebook.com/estater/">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/Estater_bits">
                                    <i class="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/company/estater/">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col footer_links">
                    <div class="row">
                        <div class="col-md-3">
                            <h5 class="text-uppercase link-heading mt-3 mb-4">{{'footer.products' | translate}}</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#!">{{'footer.mi' | translate}}</a>
                                </li>
                                <li>
                                    <a href="https://www.estater.com">{{'footer.estater' | translate}}</a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md-3">
                            <h5 class="text-uppercase link-heading mt-3 mb-4">{{'footer.company' | translate}}</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#!">{{'footer.about' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.terms' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.news_blog' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.help' | translate}}</a>
                                </li>
                            </ul>

                        </div>
                        <div class="col-md-3">
                            <h5 class="text-uppercase link-heading mt-3 mb-4">{{'footer.features' | translate}}</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#!">{{'footer.data_research' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.price_comparison' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.type_of_properties' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.map_integration' | translate}}</a>
                                </li>
                            </ul>

                        </div>
                        <div class="col-md-3">
                            <h5 class="text-uppercase link-heading mt-3 mb-4">{{'footer.geo_estater' | translate}}</h5>
                            <ul class="list-unstyled">
                                <li>
                                    <a href="#!">{{'footer.data_collection' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.cleansing' | translate}}</a>
                                </li>
                                <li>
                                    <a href="#!">{{'footer.location_analysis' | translate}}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="footer-copyright">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <span class="copyright-text">© {{year}} {{'footer.copyright' | translate}}</span>
                </div>
                <div class="col-md-6">
                    <ul class="social-link">
                        <li>
                            <a href="https://www.facebook.com/estater/" target="_blank"><i class="fa fa-facebook"></i></a>
                        </li>
                        <li>
                            <a href="https://twitter.com/EstaterLimited" target="_blank"><i class="fa fa-twitter"></i></a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/estater/about/" target="_blank"><i class="fa fa-linkedin"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- Footer -->