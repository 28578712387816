<!--whole page row  -->
<div class="top_banner text_right_ar">
    <div class="container">
        <div class="row">
            <div class="main_banner">
                <img src="../../../assets//MIAssets/Web-page/Multi-Devices-Mockup-Scene.png" class="intro-mob" />
                <div class="col-md-6 col-sm-9 col-xs-6 col-lg-5 fd-text">
                    <span class="white">
                        {{'intro.heading_content1' | translate}} 
                        {{'intro.heading_content2' | translate}} 
                        {{'intro.heading_content3' | translate}}
                    </span>
                </div>
                <img src="../../../assets//MIAssets/Web-page/Multi-Devices-Mockup-Scene.png" class="img-fluid web_banner" />
            </div>
        </div>
    </div>
</div>

<!-- explore product -->
<section class="explore-product">
    <div class="container">
        <div class="row">
            <div class="col-md-6" [ngStyle]="selectedLangCode=='ar' ? {'text-align': 'initial'} : {}">
                <h1 class="orange-text ep-heading">
                    {{'intro.explore_product_heading1' | translate}} {{'intro.explore_product_heading2' | translate}}
                </h1>
                <p class="ep-sub-heading">
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since.
                </p>
                <button 
                class="btn explore-btn btn_web" 
                [routerLink]="['/products']" 
                routerLinkActive="router-link-active">{{'intro.btn_explore' | translate}}</button>
            </div>
            <div class="col-md-6 img-responsive" [class.text-left]="selectedLangCode=='ar'">
                <img class="explore-img" src="../../assets/MIAssets/Web-page/explore.png" alt="explore" />
                <button class="btn explore-btn btn_mobile" [routerLink]="['/products']"
                    routerLinkActive="router-link-active">{{'intro.btn_explore' | translate}}</button>
            </div>
        </div>
    </div>
</section>
<!--  -->
<!-- guided tour -->
<!-- <section class="guided-tour">
    <div class="container-fluid">
        <div class="row">
            <div class="tour-alignment w-100 d-md-flex align-items-center">
                
                <div class="col-md-4 ml-md-4 pl-md-5 tour-text" [class.mr-md-auto]="selectedLangCode=='en'"
                    [class.ml-md-auto]="selectedLangCode=='ar'">
                    <h1 class="white take">
                        Take
                        a guided tour of MI tool</h1>
                    <h5 class="f-14" style="color: #DEDEDE;">See how do you get marker updated details</h5>
                </div>
               
                <div class="col-md-3 pl-md-5 text-md-center" [class.mr-md-auto]="selectedLangCode=='ar'"
                    [class.ml-md-0]="selectedLangCode=='ar'" [class.ml-md-auto]="selectedLangCode=='en'">
                    <button mat-button class="orange tour-btn lean-radius">Start Tour</button>
                </div>
                
            </div>
        </div>
    </div>
</section> -->
<!-- discover section -->
<section class="discover-section orange">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-lg-12">
                <div class="w-100">
                    <div class="discover-text d-flex justify-content-center">
                        <h1 class="ep-heading">
                            {{'intro.discover_section_heading' | translate}}
                        </h1>
                    </div>
                </div>
            </div>
            <!-- Grid column -->
            <div class="col-md-12 category-btn-grp">
                <button *ngFor="let list of categories" class="btn category-btn">
                    <span class="categories"> {{'intro.'+list.value | translate}}</span>
                </button>
            </div>
        </div>
    </div>
</section>

<section class="testimonials d-flex justify-content-center w-100 direction_ltr_ar">
    <div class="container">
        <div class="row">
            <div class="d-flex justify-content-center w-100">
                <div class="col-md-12 col-sm-12">
                    <div class="testimonial-content">
                        <h1 class="ep-heading testimonial-intro d-flex justify-content-center">
                            {{'intro.testimonials_heading' | translate}}
                        </h1>
                        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig">
                            <div ngxSlickItem>
                                <div class="testimonial-statement text_right_ar">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                    <img src="../../../assets/MIAssets/quote.svg" class="img-fluid" />
                                </div>
                                <div class="testimonial-by d-flex justify-content-center align-items-center">
                                    <div class="img-name d-flex align-items-center">
                                        <img class="person-img" src="../../../assets/MIAssets/client_person.svg"
                                            alt="" />
                                        <div class="name-desig text_right_ar">
                                            <span class="person-name mb-2">{{'intro.testimonials_personname' | translate}}</span>
                                            <span class="designation">{{'intro.testimonial_director' | translate}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div ngxSlickItem>
                                <div class="testimonial-statement text_right_ar">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                    <img src="../../../assets/MIAssets/quote.svg" class="img-fluid" />
                                </div>
                                <div class="testimonial-by d-flex justify-content-center align-items-center">
                                    <div class="img-name d-flex align-items-center">
                                        <img class="person-img" src="../../../assets/MIAssets/client_person.svg"
                                            alt="" />
                                            <div class="name-desig text_right_ar">
                                                <span class="person-name mb-2">{{'intro.testimonials_personname' | translate}}</span>
                                                <span class="designation">{{'intro.testimonial_director' | translate}}</span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div ngxSlickItem>
                                <div class="testimonial-statement text_right_ar">
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting
                                        industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                    <img src="../../../assets/MIAssets/quote.svg" class="img-fluid" />
                                </div>
                                <div class="testimonial-by d-flex justify-content-center align-items-center">
                                    <div class="img-name d-flex align-items-center">
                                        <img class="person-img" src="../../../assets/MIAssets/client_person.svg"
                                            alt="" />
                                            <div class="name-desig text_right_ar">
                                                <span class="person-name mb-2">{{'intro.testimonials_personname' | translate}}</span>
                                                <span class="designation">{{'intro.testimonial_director' | translate}}</span>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-slick-carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--  -->

<section class="tour_demo_info">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-8 col-sm-6">
                <div class="tour_info text_right_ar">
                    <h1 class="ep-heading">{{'intro.tour_demo_heading' | translate}}</h1>
                    <p>{{'intro.tour_demo_content' | translate}}</p>
                </div>
            </div>
            <div class="col-md-4 col-sm-6">
                <div class="demo_button text-right text_left_ar">
                    <button [routerLink]="['/request-demo']"
                        routerLinkActive="router-link-active">{{'intro.btn_tourdemo' | translate}}</button>
                </div>
            </div>
        </div>
    </div>
</section>