import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from '../../services/api.service';
import { SubjectService } from '../../services/subject.service';
import { GlobalService } from '../../services/global.service';
import { BrowserService } from '../../services/browser.service';
import { isPlatformBrowser } from '@angular/common';
import { Router } from '@angular/router';
import { CitySelectComponent } from '../../core/city-select/city-select.component';
declare var $:any;
// import { Router } from 'express';

@Component({
  selector: 'app-header-section',
  templateUrl: './header-section.component.html',
  styleUrls: ['./header-section.component.css']
})
export class HeaderSectionComponent implements OnInit {
  public profile_image;
  public userInfo: any;
  public userName: String;
  public mobileLoginError: boolean = false;
  public languages: any = [];
  public prodList: any = [];
  public userId;
  public loginDisabled: boolean = false;
  demodisable: boolean = false;
  cName: string;
  public staticPages = [
  {
    routerLink: '/',
    title: 'home',
  }, 
  {
    routerLink: '/products',
    title: 'product',
  },
  {
    routerLink: '/about',
    title: 'about',
  },
  {
    routerLink: '/contact',
    title: 'contact',
  },
  {
    routerLink: '',
    title: 'news_blog',
  },
]
  public selectedStaticPage: string;
  selectedLang: any;
  selectedLangCode: any;
  constructor(
    private api: ApiService,
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private dialog: MatDialog,
    private subject: SubjectService,
    private global: GlobalService,
    private browser: BrowserService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.selectedLangCode = this.global.getCurrentLangCode();
    this.userId = this.browser.getLocalValue('user_id');
    this.subject.langList.subscribe(languages=>{
      if(languages) this.languages = languages;
    })
    // this.api.getLanguageList();
    //behaviousSubject for profileImage
    this.subject.profileImage.subscribe(pfImg => {
      this.profile_image = pfImg
      iconRegistry.addSvgIcon('prof_image', sanitizer.bypassSecurityTrustResourceUrl(pfImg));
    });
    //behaviousSubject for User Name
    this.subject.userName.subscribe(uname => {
      this.userName = uname;
    });
    //selected lang for app
    this.subject.selectedLanguageForApp.subscribe(selectedLangForApp => {
      this.selectedLang = this.browser.getLocalValue('selected_language') ? JSON.parse(this.browser.getLocalValue('selected_language')).language_code : '';;
    })
    iconRegistry.addSvgIcon('remiEst', sanitizer.bypassSecurityTrustResourceUrl('assets/MIAssets/Remi_Estater_logo.svg'))
    iconRegistry.addSvgIcon('UserDefault', sanitizer.bypassSecurityTrustResourceUrl('assets/MIAssets/User_Default.svg'))
    this.userInfo = this.browser.getLocalValue('user_info') ? JSON.parse(this.browser.getLocalValue('user_info')) : '';
    // if(isPlatformBrowser(this.platformId)){
    //   if (window.location.href.includes('login')) {
    //     this.loginDisabled = true;
    //   }
    //   else{
    //     this.loginDisabled = false;
    //   }
    // }
    router.events.subscribe((url: any) => {
      if (url.url != undefined && url.url == '/request-demo') {
        this.demodisable = true;
      }
      else {
        this.demodisable = false;
      }
    });
    // router.events.subscribe((url: any) => {
    //   if (url.url != undefined && url.url == '/login') {
    //     this.loginDisabled = true;
    //   }
    //   else {
    //     this.loginDisabled = false;
    //   }
    // });
  }
  // openLoginDialog() {
  //   const dialogRef = this.dialog.open(LoginComponent, {
  //     width: "450px",
  //     panelClass: "login-dialog",
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //   });
  // }

  loginOnMobile(condition) {
  }
  logout(val) {
    this.api.logout(val);

    // setTimeout(() => {
    //   if (isPlatformBrowser(this.platformId)) {
    //     window.location.reload();
    //   }
    // }, 1500);

  }

  // getLanguageList() {
  //   this.api.getUmsData('language')
  //     .subscribe(
  //       (res: any) => {
  //         if (res && res.data) {
  //           res.data.forEach(element => {
  //             if (element.status == 1) {
  //               this.languages.push(element);
  //             }
  //           });
  //         }
  //       }
  //     );
  // }
  selectedLanguage(lng, reload: boolean = false) {
    this.browser.setLocalValue('language_id', lng.language_id);
    this.selectedLang = this.browser.getLocalValue('language_id');
    this.subject.selectedLanguageForApp.next(this.selectedLang);
    this.global.changeLanguage(lng, reload);
  }


  //function to set active class on selection of Static Pages
  closenav:boolean=false;
  slectedPage(page) {
    this.selectedStaticPage = page;
    this.closenav = true;
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      $(document).ready(function(e){
        $(document).on('click', '.navbar-nav li', function(){
          $('.btn_hamburger input').prop('checked', false);
          if ($(window).width() >= 600) { 
            e.preventDefault();
            e.stopPropagation();
          }   
        }); 
      });
    }
  }
  getCity() {
    if (this.browser.getLocalValue('city')) {
      this.cName = JSON.parse(this.browser.getLocalValue('city')).place_name;
    }
    return this.cName;
  }
  citySelect() {
    const dialogRef = this.dialog.open(CitySelectComponent, {
      width: "450px",
      panelClass: "city-dialog",
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
