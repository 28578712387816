import { Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GlobalService } from '../../services/global.service';
// import { isPlatformBrowser } from '@angular/common';
// declare var $:any;

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  categories = [{
    value: 'city_wise_data'
  },
  {
    value: 'district_wise_data'
  },
  {
    value: 'business_growth_value'
  },
  {
    value: 'property_type_detail'
  },
  {
    value: 'lease_sale_price'
  },
  {
    value: 'industrial_land_data'
  },
  {
    value: 'map_intersection'
  },
  {
    value: 'business_development'
  }
    , {
    value: 'population_study'
  }
    , {
    value: 'district_amenities'
  }
    , {
    value: 'all_retail_properties'
  },
  {
    value: 'price_comparison'
  }]
  deviceInfo = null;
  isMobile: boolean;
  isTablet: boolean;
  isDesktopDevice: boolean;
  selectedLangCode: any;
  constructor(translate: TranslateService,
    private deviceService: DeviceDetectorService,
    private global: GlobalService) {
    this.selectedLangCode = this.global.getCurrentLangCode();
    this.deviceDetect();
    // translate.setDefaultLang('ar');
    // translate.use('ar');
  }
  deviceDetect() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.isDesktopDevice = this.deviceService.isDesktop();
  }

  ngOnInit(): void {
  }
  slideConfig = {
    "slidesToShow": 1, 
    "slidesToScroll": 1,
    "dots": true
    // "autoplay":true
  };
}
