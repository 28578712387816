<section class="banner_design">
    <div class="container">
        <div class="row">
            <div class="w-100 intro d-flex align-items-center row_reverse_ar">
                <div fxLayout="column" class="banner-text direction_ltr_ar">
                    <span class="mb-2"> {{'product.topbanner_paragraph1' | translate}} {{'product.topbanner_paragraph2' | translate}}</span>
                    <a *ngIf="profile_image" class="view-product" href="/reports/report-group/objectives">{{'product.view_product' | translate}}</a>
                    <a *ngIf="!profile_image" class="view-product" href="/login">{{'product.view_product' | translate}}</a>
                </div>
                <img src="../../../../assets/MIAssets/Web-page/product_banner_illus.svg" class="img-fluid banner_image" />
                <img src="../../../assets/MIAssets/Web-page/product_banner_illus_ar.svg" class="img-fluid banner_image banner_image_ar" />
            </div>
        </div>
    </div>
</section>

<section class="product-detail">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h2 class="leading-text">{{'product.productdetail_heading' | translate}}</h2>
            </div>
            <div class="col-md-12 pl-0 pr-0">
                <div class="prod-links">
                    <ng-container *ngFor="let list of prodList">
                        <button class="btn mr-1 ml-1 col-md-2 text-center text-uppercase"
                            [ngClass]="{active: selectedProduct==list.name}"
                            (click)="showProduct(list.name)">{{'product.'+list.name | translate}}</button>
                    </ng-container>
                </div>
            </div>
            <!-- data -->
            <ng-container *ngIf="selectedProduct=='data'">
                <div class="container">
                    <span class="d-flex justify-content-center text-center maintain-text">
                        {{'product.data_tab1_p1' | translate}} {{'product.data_tab1_p2' | translate}}</span>
                </div>
                <div class="container pl-0 pr-0">
                    <div class="row align-items-baseline mr-0 ml-0 w-100">
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/transaction.svg" alt="">
                            <span class="service-name mb-3">{{'product.transcation' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4 h_50p"
                                src="../../../../assets/MIAssets/Web-page/products/data/lease_sale_rate.svg" alt="">
                            <span class="service-name mb-3">{{'product.lease_sale_rate' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/market_research.svg" alt="">
                            <span class="service-name mb-3">{{'product.market_research' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/district_wise_data.svg" alt="">
                            <span class="service-name mb-3">{{'product.district_wise_data' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/citywise_data.svg" alt="">
                            <span class="service-name mb-3">{{'product.city_wisedata' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/annual_growth.svg" alt="">
                            <span class="service-name mb-3">{{'product.annual_growth_rate' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/population_nationality.svg" alt="">
                            <span class="service-name mb-3">{{'product.population_nationality' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/data/amenities.svg" alt="">
                            <span class="service-name mb-3">{{'product.amenities' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--  -->
            <!-- analytics -->
            <ng-container *ngIf="selectedProduct=='analytics'">
                <div class="container">
                    <span
                        class="d-flex justify-content-center text-center maintain-text">
                        {{'product.analytics_tab2_p1' | translate}} {{'product.analytics_tab2_p2' | translate}} 
                        {{'product.analytics_tab2_p3' | translate}}
                    </span>
                </div>
                <div class="container pl-0 pr-0">
                    <div class="row align-items-baseline w-100 mr-0 ml-0">
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/retail_fb.svg" alt="">
                            <span class="service-name mb-3">{{'product.retail_tb' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/industrial.svg" alt="">
                            <span class="service-name mb-3">{{'product.industrial' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/residential.svg" alt="">
                            <span class="service-name mb-3">{{'product.residential' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/commercial_segment.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.commercial_segment' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/land_parcel.svg" alt="">
                            <span class="service-name mb-3">{{'product.land_parcel' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and
                                typesetting
                                industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Analytics/hospitality.svg" alt="">
                            <span class="service-name mb-3">{{'product.hospitality' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--  -->
            <!-- services -->
            <ng-container *ngIf="selectedProduct=='services'">
                <div class="container">
                    <span
                        class="d-flex justify-content-center text-center maintain-text">
                        {{'product.services_tab3_p1' | translate}} {{'product.services_tab3_p2' | translate}}
                    </span>
                </div>
                <div class="container pl-0 pr-0">
                    <div class="row align-items-baseline w-100 mr-0 ml-0">
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/project_feasibility_study.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.project_feasibility_study' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/market_research.svg" alt="">
                            <span class="service-name mb-3">{{'product.market_research' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/marketoutlook_study.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.market_outlook_study' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/property_valuation.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.property_valuation' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/market_gap_analysis.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.market_gap_analysis' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Services/design_optimization.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.design_optimization' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--  -->
            <!-- technology -->
            <ng-container *ngIf="selectedProduct=='technology'">
                <div class="container">
                    <span class="d-flex justify-content-center text-center maintain-text">
                        {{'product.technology_tab4_p1' | translate}} {{'product.technology_tab4_p2' | translate}}
                    </span>
                </div>
                <div class="container pl-0 pr-0">
                    <div class="row align-items-baseline w-100 mr-0 ml-0">
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/land_parcel_data.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.land_parcel_data' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/data_collection.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.data_collection' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/cleansing.svg" alt="">
                            <span class="service-name mb-3">{{'product.cleansing' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/locationalytics.svg"
                                alt="">
                            <span
                                class="service-name mb-3">{{'product.locationalytics' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/mapping_visualization.svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.mapping_visualization' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                        <div class="col-md-4 service text-center">
                            <img class="mb-4"
                                src="../../../../assets/MIAssets/Web-page/products/Technology/built_analysis .svg"
                                alt="">
                            <span class="service-name mb-3">{{'product.built_in_analysis' | translate}}</span>
                            <p class="service-detail">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!--  -->
        </div>
    </div>
</section>
<section class="trusted-clients direction_ltr_ar">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <span class="trusted-text  d-flex justify-content-center">
                    {{'product.trustclient_heading' | translate}}
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_1.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_2.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_3.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_4.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_5.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_6.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_7.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_8.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_9.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_10.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_11.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_12.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_13.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_14.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_15.png" class="img-fluid" />
                </div>
            </div>
            <div class="col-6 col-lg-3 col-md-3 col-sm-6 truested_client">
                <div class="client_logo">
                    <img src="../../../assets/MIAssets/Web-page/products/client_logo_16.png" class="img-fluid" />
                </div>
            </div>
        </div>
    </div>
</section>