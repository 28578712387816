import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalService } from '../../services/global.service';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css'],
})
export class ContactUsComponent implements OnInit {
  selectedLangCode: string;
  contactForm: FormGroup;
  btnDisable = false;

  constructor(
    private fb: FormBuilder,
    private global: GlobalService,
    private alert: AlertService,
    private apiService: ApiService
  ) {
    this.selectedLangCode = this.global.getCurrentLangCode();
  }

  ngOnInit(): void {
    this.createFormElement();
  }

  createFormElement() {
    this.contactForm = this.fb.group({
      name: ['', Validators.required],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });
  }

  contactSubmit() {
    if (this.contactForm.invalid) {
      return;
    }
    this.btnDisable = true;
    this.apiService.contactUs(this.contactForm.value).subscribe((res:any)=>{
      this.contactForm.reset();
      this.btnDisable = false;
      if(res && res.status == 201){
        this.alert.success(res.message);
        
      }else{
        this.alert.error(res.message)
      }
    }, err=>{
      this.alert.error('Something went Wrong ! Try Again');      
    })
    
  }

  imgSrc: string = '../../../assets/MIAssets/Web-page/contact/blog.svg';
  onMouseOver(): void {
    this.imgSrc = '../../../assets/MIAssets/Web-page/contact/blog_hover.svg';
  }
  onMouseOut(): void {
    this.imgSrc = '../../../assets/MIAssets/Web-page/contact/blog.svg';
  }
}
