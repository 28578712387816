import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../services/global.service';
import { BrowserService } from '../services/browser.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mi-home',
  templateUrl: './mi-home.component.html',
  styleUrls: ['./mi-home.component.css']
})
export class MiHomeComponent implements OnInit {
  selectedCode;
  currentRoute: string;
  currentLang: string = '';
  languageList = [{
    language_code: "en",
    language_id: 1,
    name: "English-US",
    status: 1
  }, {
    language_code: "ar",
    language_id: 2,
    name: "Arabic",
    status: 1
  }
  ]

  constructor(private browser:BrowserService,
    private translate: TranslateService, 
    private globalService: GlobalService,
    private route:Router,
    private dialog: MatDialog) {
      this.currentRoute= this.route.url;
    this.currentLang = this.browser.getLocalValue('language');
    if (this.currentLang && this.currentLang == 'ar') {
      this.changeLanguage(this.languageList[1], false);
    } else {
      this.changeLanguage(this.languageList[0], false);
    }
  }

  ngOnInit(): void {
    this.translate.use(this.browser.getLocalValue('language'))
    this.dialog.closeAll();
  }
  changeLanguage(language: any, reload: boolean) {
    this.browser.setLocalValue('language', language.language_code);
    this.browser.setLocalValue('selected_language', JSON.stringify(language));
    this.globalService.changeLanguage(language, reload);
  }
}
