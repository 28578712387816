<div class="container-fluid">
    <app-loader *ngIf="loading"></app-loader>
    <div class="row">
        <div class="col-md-12">
            <div class="icon-container">
                <div *ngFor="let cities of cityList" class="box" (click)="citySelect(cities,'view')" [ngClass]="{active: cities.place_id==selectedCity.place_id}">
                    <div id="content-mob">
                        <div style="display: flex;flex-direction: column;justify-content: center;align-items: center; white-space: nowrap;">
                            <img class="countrycons" src="{{cities.image}}" alt="{{cities.place_name}}" onerror="this.src='assets/MIAssets/Country/Others.png'">
                            <span>{{cities.place_name}}</span>
                        </div>
                        <!-- <div *ngIf="cities.place_name=='Kuwait' || cities.place_name=='Bahrain' || cities.place_name=='Riyadh' || cities.place_name=='Dubai' || cities.place_name=='Sharajh'"
                            style="display: flex;flex-direction: column;justify-content: center;align-items: center; white-space: nowrap;">
                            <mat-icon *ngIf="cities.place_id!=selectedCity.place_id" class="countrycons {{cities.place_name}}"
                                svgIcon="{{cities.place_name}}"></mat-icon>
                            <mat-icon *ngIf="cities.place_id==selectedCity.place_id" class="countrycons"
                                svgIcon="{{cities.place_name}}_Active"></mat-icon>
                            <span>{{cities.place_name}}</span>
                        </div>
                        <div *ngIf="cities.place_name!='Kuwait' && cities.place_name!='Bahrain' && cities.place_name!='Riyadh' && cities.place_name!='Dubai' && cities.place_name!='Sharajh'"
                            style="display: flex;flex-direction: column;justify-content: center;align-items: center; white-space: nowrap;">
                            <mat-icon class="countrycons" svgIcon="other"></mat-icon>
                            <span>{{cities.place_name}}</span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>