import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { ApiService } from './api.service';
import { BrowserService } from './browser.service';

@Injectable()
export class GlobalService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private translate: TranslateService,
    private api: ApiService,
    private browser: BrowserService,
    private _router: Router
  ) {}

  dashboardComponentList = [];
  updateDashboard$ = new Subject<any>();
  updateDashboardLayout$ = new Subject<any>();

  getCurrentLangCode() {
    return this.browser.getLocalValue('language');
  }
  changeLanguage(language_: any, reload: boolean = false): void {
    let language = language_.language_code;
    // if (isPlatformBrowser(this.platformId)) {
    //   if (
    //     language !== 'ar' &&
    //     document.getElementsByTagName('html')[0].hasAttribute('dir')
    //   ) {
    //     document.getElementsByTagName('html')[0].removeAttribute('dir');
    //   } else if (
    //     language === 'ar' &&
    //     !document.getElementsByTagName('html')[0].hasAttribute('dir')
    //   ) {
    //     document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    //   }
    // }
    let arb = {
      language_code: 'ar',
      language_id: 2,
      name: 'Arabic',
      status: 1,
    };

    if (language) {
      this.browser.setLocalValue('language', language_.language_code);
      this.browser.setLocalValue(
        'selected_language',
        JSON.stringify(language_)
      );
    } else {
      this.browser.setLocalValue('selected_language', JSON.stringify(arb));
    }
    this.translate.use(language_.language_code);
    this.api.language = language_.language_id;
    if (reload) {
      if (isPlatformBrowser(this.platformId)) {
        window.location.reload();
      }
    }
  }

  setLanguageDoc(language_: any, reload: boolean = false): void {
    let language = language_.language_code;
    this.translate.use(language_.language_code);
    if (isPlatformBrowser(this.platformId)) {
      if (
        language !== 'ar' &&
        document.getElementsByTagName('html')[0].hasAttribute('dir')
      ) {
        document.getElementsByTagName('html')[0].removeAttribute('dir');
      } else if (
        language === 'ar' &&
        !document.getElementsByTagName('html')[0].hasAttribute('dir')
      ) {
        document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      }
    }
  }

  reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }

  downloadFile(filename, csvData) {
    if (isPlatformBrowser(this.platformId)) {
      // var link = document.createElement("a");
      // link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvData));
      // link.setAttribute("download", `${filename}_${+new Date}.csv`);
      //  let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      // if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      //     link.setAttribute("target", "_blank");
      // }
      // link.style.visibility = "hidden";
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

      let blob = new Blob([csvData], {
        type: 'text/csv;charset=utf-8,%EF%BB%BF',
      });
      let dwldLink = document.createElement('a');
      let url = URL.createObjectURL(blob);
      let isSafariBrowser =
        navigator.userAgent.indexOf('Safari') != -1 &&
        navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {
        //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute('target', '_blank');
      }
      dwldLink.setAttribute('href', url);
      dwldLink.setAttribute('download', filename + '_' + +new Date() + '.csv');
      dwldLink.style.visibility = 'hidden';
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
  }

  ConvertToCSV(headerList) {
    let str = '';
    let row = '';
    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    return str;
  }
  checkResourceAccess(name, flag?) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false,
    };
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    if (resources && resources.length) {
      resources.forEach((element) => {
        if (element.resource_name == name && element.methods) {
          if (element.methods.indexOf('GET') != -1) {
            resource.GET = true;
          }
          if (element.methods.indexOf('POST') != -1) {
            resource.POST = true;
          }
          if (element.methods.indexOf('PATCH') != -1) {
            resource.PATCH = true;
          }
          if (element.methods.indexOf('DELETE') != -1) {
            resource.DELETE = true;
          }
        }
      });
    }
    return resource;
  }

  // int value in string
  intToString(num) {
    num = num.toString().replace(/[^0-9.]/g, '');
    if (num < 1000) {
      return num;
    }
    let si = [
      { v: 1e3, s: 'K' },
      { v: 1e5, s: 'L' },
      { v: 1e6, s: 'M' },
      { v: 1e9, s: 'B' },
      { v: 1e12, s: 'T' },
      { v: 1e15, s: 'P' },
      { v: 1e18, s: 'E' },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(1).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') +
      si[index].s
    );
  }

  updateDashboard(data) {
    this.updateDashboard$.next(data);
  }

  updateDashboardLayout(data) {
    this.updateDashboardLayout$.next(data);
  }

  getDefaultFilter(reportR){
    let mergerparam = {};
    reportR.filter.forEach((data: any) => {
      if (data && data.default) {
        mergerparam[data.parameter_name] =
          data.type == 'range'
            ? this.getRangeValue(data.default)
            : data.default;

            if(reportR.query.component.every(v => v === 'employment-data' )  && data.parameter_name === 'year'){
              mergerparam['quarter'] = JSON.stringify({
                [data.default] : [1,2,3,4]
              });
            }
      }
    });
    return mergerparam;
  }

  setSavedFilterData(defaultFilter, saved){
    let filter_data = {...saved};
    if(filter_data?.quarter){
      filter_data.quarter = JSON.stringify(filter_data.quarter);
    }
    if(Object.keys(defaultFilter).length > 0){
      Object.keys(defaultFilter).forEach(v => {
        if(!filter_data[v]){
          filter_data[v] = defaultFilter[v]
        }
      })
    }
    return filter_data;
  }

  getRangeValue(rangeValue: string) {
    if (rangeValue) {
      const val = rangeValue.split('|');
      if (val[0]) {
        return val[0];
      }
      return rangeValue;
    }
  }
}
