import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BrowserService } from './services/browser.service';

@Component({
  selector: 'app-page-404',
  template: `  
    <div style="position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    ">
    <div class="">
      <div class="text-center" style="margin:3rem;">
          <h3 style="color:#92725D; font-size: 30px;">Opps! Something went wrong!</h3>
          <h4 style="color:#92725D; font-size: 20px; font-weight: 500 !important;">The page you're looking for not available</h4>
      </div>
      <div class="text-center">     
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="100%" height="388.424" viewBox="0 0 877 388.424">
  <defs>
    <linearGradient id="linear-gradient" x1="0.488" y1="1.132" x2="0.5" y2="-0.168" gradientUnits="objectBoundingBox">
      <stop offset="0" stop-color="#fff5ec"/>
      <stop offset="1" stop-color="#fff"/>
    </linearGradient>
  </defs>
  <g id="Group_4869" data-name="Group 4869" transform="translate(-118 -283.576)">
    <g id="Group_4843" data-name="Group 4843" transform="translate(150 -21.424)">
      <path id="Path_22751" data-name="Path 22751" d="M343.621,0C533.4,0,687.242,153.844,687.242,343.621H0C0,153.844,153.844,0,343.621,0Z" transform="translate(62.808 309)" fill="url(#linear-gradient)"/>
      <path id="Union_66" data-name="Union 66" d="M207.367,134V27.825H264V134ZM171.8,134H127.467V0h50.892V55.651h29.007V134Zm-136.79,0H0V55.651H45.788V70.3H80.793V134Z" transform="translate(334 558.424)" fill="#f6ece6"/>
      <g id="Group_4842" data-name="Group 4842" transform="translate(197.483 367.086)">
        <path id="Union_67" data-name="Union 67" d="M381.5,304.338V183.1h.123V151.8H301.181V124.089L383.81,2.4h36.738V120.866h22.688V151.8H420.548v31.3h.385V304.338ZM370.791,68.66l-34.547,52.206h45.374V88.9q0-7.993.644-23.2t1.031-17.66h-1.031A143.778,143.778,0,0,1,370.791,68.66ZM81.139,304.338V190.863h-.9V151.8H-.2V124.089L82.427,2.4h36.739V120.866h22.687V151.8H119.166v31.3h.679V304.338ZM69.408,68.66,34.861,120.866H80.236V88.9q0-7.993.645-23.2t1.031-17.66H80.881A143.786,143.786,0,0,1,69.408,68.66ZM172.082,168.949q-16.564-24.492-16.564-72.317,0-49.887,16.114-73.541T221.26-.562q32.613,0,49.307,24.75t16.693,72.445q0,49.372-16.177,73.09T221.26,193.441Q188.646,193.441,172.082,168.949ZM201.15,46.81q-6.057,15.147-6.058,49.822t5.994,49.694q5.994,15.017,20.173,15.017,13.923,0,20.11-15.211t6.188-49.5q0-34.675-6.252-49.822T221.26,31.664Q207.209,31.664,201.15,46.81Z" transform="translate(0 0)" fill="#efe0d7"/>
      </g>
      <g id="Group_4868" data-name="Group 4868" transform="translate(197.483 357.086)">
        <path id="Union_67-2" data-name="Union 67" d="M381.5,304.338V183.1h.123V151.8H301.181V124.089L383.81,2.4h36.738V120.866h22.688V151.8H420.548v39.059H420.5V304.338ZM370.791,68.66l-34.547,52.206h45.374V88.9q0-7.993.644-23.2t1.031-17.66h-1.031A143.778,143.778,0,0,1,370.791,68.66ZM80.138,304.338V183.1h.1V151.8H-.2V124.089L82.427,2.4h36.739V120.866h22.687V151.8H119.166v39.059h-.028V304.338ZM69.408,68.66,34.861,120.866H80.236V88.9q0-7.993.645-23.2t1.031-17.66H80.881A143.786,143.786,0,0,1,69.408,68.66ZM172.082,168.949q-16.564-24.492-16.564-72.317,0-49.887,16.114-73.541T221.26-.562q32.613,0,49.307,24.75t16.693,72.445q0,49.372-16.177,73.09T221.26,193.441Q188.646,193.441,172.082,168.949ZM201.15,46.81q-6.057,15.147-6.058,49.822t5.994,49.694q5.994,15.017,20.173,15.017,13.923,0,20.11-15.211t6.188-49.5q0-34.675-6.252-49.822T221.26,31.664Q207.209,31.664,201.15,46.81Z" transform="translate(0 0)" fill="#fff" stroke="#dac6b9" stroke-width="1.5"/>
      </g>
      <path id="Union_65" data-name="Union 65" d="M130.911,106V0H196V106Zm-23.4,0V27.779h23.4V106Zm-38.76,0V18.275h38.76V106Zm-38.761,0H0V7.31H29.986V43.13H68.747V106Z" transform="translate(617 585.424)" fill="#efe0d7"/>
      <g id="Group_4841" data-name="Group 4841" transform="translate(572.58 604.054)">
        <rect id="Rectangle_1106" data-name="Rectangle 1106" width="26" height="87" transform="translate(0.42 0.37)" fill="#efe0d7"/>
        <path id="Path_22744" data-name="Path 22744" d="M26939.723,11171.274H26937v14.37h2.723Zm0-33.138H26937v14.37h2.723Zm0-33.137H26937v14.369h2.723Z" transform="translate(-26924.135 -11098.005)" fill="#dac6b9" fill-rule="evenodd"/>
      </g>
      <rect id="Rectangle_1107" data-name="Rectangle 1107" width="33" height="78" transform="translate(88 613.424)" fill="#efe0d7"/>
      <path id="Union_63" data-name="Union 63" d="M100.917,106V0H166V106Zm-23.4,0V27.779h23.4V106Zm-38.758,0V18.275H77.516V106ZM0,106V43.131H38.758V106Z" transform="translate(156 585.424)" fill="#efe0d7"/>
      <g id="Group_4836" data-name="Group 4836" transform="translate(316.964 597.481)">
        <rect id="Rectangle_1103" data-name="Rectangle 1103" width="29" height="95" transform="translate(0.036 -0.057)" fill="#fefefe"/>
        <path id="Path_22731" data-name="Path 22731" d="M23218,8323.279h2.912v11.549H23218Zm0-26.615h2.912v11.5H23218Zm0-26.665h2.912v11.55H23218Z" transform="translate(-23204.674 -8257.908)" fill="none" stroke="#9d9e9e" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="_394349760" transform="translate(187.403 642.762)">
        <path id="Path_22658" data-name="Path 22658" d="M1996.293,11073H1995v-3h1.293Z" transform="translate(-1988.832 -11063.857)" fill="#ffaf68" stroke="#b7cbd8" stroke-width="1" fill-rule="evenodd"/>
        <path id="Path_22659" data-name="Path 22659" d="M1701.608,10809.225h.329c15.334,0,3.448-38.9.041-38.43C1700.725,10770.96,1686.069,10809.225,1701.608,10809.225Z" transform="translate(-1694.516 -10770.791)" fill="#ffaf68" fill-rule="evenodd"/>
        <path id="Path_22660" data-name="Path 22660" d="M1998.375,11852l-1.375,26.008h2.278l-.369-14.676,2.176-4.6-.123-.205-2.094,3-.247-9.525Z" transform="translate(-1990.791 -11829.807)" fill="#ffaf68" fill-rule="evenodd"/>
      </g>
      <g id="Group_4840" data-name="Group 4840" transform="translate(407.525 567.537)">
        <rect id="Rectangle_1104" data-name="Rectangle 1104" width="35" height="124" transform="translate(0.475 -0.113)" fill="#efe0d7"/>
        <path id="Path_22732" data-name="Path 22732" d="M22536.561,7825h-2.207v10.093h2.207Zm0,68.093h-2.207v10.093h2.207Zm0-21.392h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm12.352-23.351h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm0-23.351H22522v10.093h2.209Z" transform="translate(-22511.498 -7815.239)" fill="#dac6b9" fill-rule="evenodd"/>
      </g>
      <g id="Group_4837" data-name="Group 4837" transform="translate(281.908 568.268)">
        <rect id="Rectangle_1104-2" data-name="Rectangle 1104" width="35" height="124" transform="translate(0.092 0.156)" fill="#fefefe"/>
        <path id="Path_22732-2" data-name="Path 22732" d="M22536.561,7825h-2.207v10.093h2.207Zm0,68.093h-2.207v10.093h2.207Zm0-21.392h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm12.352-23.351h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm0-23.351H22522v10.093h2.209Z" transform="translate(-22511.498 -7815.239)" fill="none" stroke="#b2b3b3" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="Group_4828" data-name="Group 4828" transform="translate(432.356 577.762)">
        <path id="Path_22733" data-name="Path 22733" d="M27201.424,10112l22.463,16.979v97.422H27179v-97.422Z" transform="translate(-27179 -10112)" fill="#fefefe" fill-rule="evenodd"/>
        <path id="Path_22734" data-name="Path 22734" d="M27439,10566h1.859v9.634H27439Zm0,65.624h1.859v9.634H27439Zm20.563,0h1.814v9.634h-1.814Zm-10.281,0h1.859v9.634h-1.859ZM27439,10610.5h1.859v9.634H27439Zm20.563,0h1.814v9.634h-1.814Zm-10.281,0h1.859v9.634h-1.859Zm-10.281-22.25h1.859v9.634H27439Zm20.563,0h1.814v9.634h-1.814Zm-10.281,0h1.859v9.634h-1.859Zm10.281-22.249h1.814v9.634h-1.814Zm-10.281,0h1.859v9.634h-1.859Z" transform="translate(-27427.77 -10546.386)" fill="none" stroke="#b2b3b3" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="Group_4834" data-name="Group 4834" transform="translate(708.422 574.111)">
        <rect id="Rectangle_1106-2" data-name="Rectangle 1106" width="27" height="117" transform="translate(-0.421 0.314)" fill="#efe0d7"/>
      </g>
      <g id="Group_4838" data-name="Group 4838" transform="translate(674.786 604.784)">
        <path id="Path_22625" data-name="Path 22625" d="M6440,9468l20,17.754v69.6H6420v-69.6Z" transform="translate(-6420 -9468)" fill="#fff" fill-rule="evenodd"/>
        <path id="Path_22626" data-name="Path 22626" d="M6767,10327h1.914v10.073H6767Zm0,46.561h1.914v10.074H6767Zm21.486,0h1.914v10.074h-1.914Zm-10.743,0h1.914v10.074h-1.914ZM6767,10350.28h1.914v10.073H6767Zm21.486,0h1.914v10.073h-1.914Zm-10.743,0h1.914v10.073h-1.914Zm10.743-23.28h1.914v10.073h-1.914Zm-10.743,0h1.914v10.073h-1.914Z" transform="translate(-6758.697 -10306.447)" fill="#fff" stroke="#c5c6c6" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="Group_4839" data-name="Group 4839" transform="translate(121.235 567.537)">
        <rect id="Rectangle_1104-3" data-name="Rectangle 1104" width="35" height="124" transform="translate(-0.234 -0.113)" fill="#efe0d7"/>
        <path id="Path_22732-3" data-name="Path 22732" d="M22536.561,7825h-2.207v10.093h2.207Zm0,68.093h-2.207v10.093h2.207Zm0-21.392h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm12.352-23.351h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm0-23.351H22522v10.093h2.209Z" transform="translate(-22511.498 -7815.239)" fill="#dac6b9" fill-rule="evenodd"/>
      </g>
      <path id="Union_64" data-name="Union 64" d="M0,142V0H21.369V36.41H42V142Z" transform="translate(0 549.424)" fill="#efe0d7"/>
      <g id="Group_4826" data-name="Group 4826" transform="translate(67.19 597.481)">
        <rect id="Rectangle_1103-2" data-name="Rectangle 1103" width="29" height="95" transform="translate(-0.19 -0.057)" fill="#fefefe"/>
        <path id="Path_22731-2" data-name="Path 22731" d="M23218,8323.279h2.912v11.549H23218Zm0-26.615h2.912v11.5H23218Zm0-26.665h2.912v11.55H23218Z" transform="translate(-23204.674 -8257.908)" fill="none" stroke="#9d9e9e" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="Group_4827" data-name="Group 4827" transform="translate(32.134 568.268)">
        <rect id="Rectangle_1104-4" data-name="Rectangle 1104" width="35" height="124" transform="translate(-0.135 0.156)" fill="#fefefe"/>
        <path id="Path_22732-4" data-name="Path 22732" d="M22536.561,7825h-2.207v10.093h2.207Zm0,68.093h-2.207v10.093h2.207Zm0-21.392h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm12.352-23.351h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm0-23.351H22522v10.093h2.209Z" transform="translate(-22511.498 -7815.239)" fill="none" stroke="#b2b3b3" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <g id="_394356816" transform="translate(594.075 568.998)">
        <rect id="Rectangle_1089" data-name="Rectangle 1089" width="31.404" height="123.426" transform="translate(0 0)" fill="#fff"/>
        <path id="Path_22627" data-name="Path 22627" d="M2721,8356h1.914v10.073H2721Zm0,67.9h1.914v10.073H2721Zm0-21.366h1.914v10.073H2721Zm10.743,0h1.914v10.073h-1.914ZM2721,8379.256h1.914v10.1H2721Zm10.743,0h1.914v10.1h-1.914Zm0-23.256h1.914v10.073h-1.914Z" transform="translate(-2712.131 -8346.678)" fill="#fff" stroke="#c5c6c6" stroke-width="1" fill-rule="evenodd"/>
      </g>
      <path id="Path_22662" data-name="Path 22662" d="M7026.973,11316.751h1.1v-23.687a9.5,9.5,0,0,0-1.436-5.6,4.737,4.737,0,0,0-4.043-1.867,5.33,5.33,0,0,0-5.24,3.063,11.327,11.327,0,0,0-.91,4.882h2.872v.693H7013v-.693h2.872a11.881,11.881,0,0,1,.933-5.145,5.9,5.9,0,0,1,5.791-3.4,5.323,5.323,0,0,1,4.522,2.105,10.108,10.108,0,0,1,1.556,5.958v23.688h1.053v12.131h-2.752Z" transform="translate(-6802.843 -10638.222)" fill="#c6ad9c" stroke="#c6ad9c" stroke-width="1" fill-rule="evenodd"/>
      <path id="Path_22748" data-name="Path 22748" d="M22536.561,7825h-2.207v10.093h2.207Zm0,68.093h-2.207v10.093h2.207Zm0-21.392h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm12.352-23.351h-2.207v10.093h2.207Zm-12.352,0H22522v10.093h2.209Zm0-23.351H22522v10.093h2.209Z" transform="translate(-22039.705 -7253.544)" fill="#e2d5ce" fill-rule="evenodd"/>
      <path id="Path_22749" data-name="Path 22749" d="M7026.973,11316.751h1.1v-23.687a9.5,9.5,0,0,0-1.436-5.6,4.737,4.737,0,0,0-4.043-1.867,5.33,5.33,0,0,0-5.24,3.063,11.327,11.327,0,0,0-.91,4.882h2.872v.693H7013v-.693h2.872a11.881,11.881,0,0,1,.933-5.145,5.9,5.9,0,0,1,5.791-3.4,5.323,5.323,0,0,1,4.522,2.105,10.108,10.108,0,0,1,1.556,5.958v23.688h1.053v12.131h-2.752Z" transform="translate(-6500.67 -10638.222)" fill="#c6ad9c" stroke="#c6ad9c" stroke-width="1" fill-rule="evenodd"/>
      <g id="_394349760-2" data-name="_394349760" transform="translate(20.813 642.762)">
        <path id="Path_22658-2" data-name="Path 22658" d="M1996.293,11073H1995v-3h1.293Z" transform="translate(-1988.832 -11063.857)" fill="#ffaf68" stroke="#b7cbd8" stroke-width="1" fill-rule="evenodd"/>
        <path id="Path_22659-2" data-name="Path 22659" d="M1701.608,10809.225h.329c15.334,0,3.448-38.9.041-38.43C1700.725,10770.96,1686.069,10809.225,1701.608,10809.225Z" transform="translate(-1694.516 -10770.791)" fill="#ffaf68" fill-rule="evenodd"/>
        <path id="Path_22660-2" data-name="Path 22660" d="M1998.375,11852l-1.375,26.008h2.278l-.369-14.676,2.176-4.6-.123-.205-2.094,3-.247-9.525Z" transform="translate(-1990.791 -11829.807)" fill="#ffaf68" fill-rule="evenodd"/>
      </g>
      <g id="_394349760-3" data-name="_394349760" transform="translate(734.714 642.762)">
        <path id="Path_22658-3" data-name="Path 22658" d="M1996.293,11073H1995v-3h1.293Z" transform="translate(-1988.832 -11063.857)" fill="#ffaf68" stroke="#b7cbd8" stroke-width="1" fill-rule="evenodd"/>
        <path id="Path_22659-3" data-name="Path 22659" d="M1701.608,10809.225h.329c15.334,0,3.448-38.9.041-38.43C1700.725,10770.96,1686.069,10809.225,1701.608,10809.225Z" transform="translate(-1694.516 -10770.791)" fill="#ffaf68" fill-rule="evenodd"/>
        <path id="Path_22660-3" data-name="Path 22660" d="M1998.375,11852l-1.375,26.008h2.278l-.369-14.676,2.176-4.6-.123-.205-2.094,3-.247-9.525Z" transform="translate(-1990.791 -11829.807)" fill="#ffaf68" fill-rule="evenodd"/>
      </g>
      <g id="_394349760-4" data-name="_394349760" transform="translate(489.576 642.762)">
        <path id="Path_22658-4" data-name="Path 22658" d="M1996.293,11073H1995v-3h1.293Z" transform="translate(-1988.832 -11063.857)" fill="#ffaf68" stroke="#b7cbd8" stroke-width="1" fill-rule="evenodd"/>
        <path id="Path_22659-4" data-name="Path 22659" d="M1701.608,10809.225h.329c15.334,0,3.448-38.9.041-38.43C1700.725,10770.96,1686.069,10809.225,1701.608,10809.225Z" transform="translate(-1694.516 -10770.791)" fill="#ffaf68" fill-rule="evenodd"/>
        <path id="Path_22660-4" data-name="Path 22660" d="M1998.375,11852l-1.375,26.008h2.278l-.369-14.676,2.176-4.6-.123-.205-2.094,3-.247-9.525Z" transform="translate(-1990.791 -11829.807)" fill="#ffaf68" fill-rule="evenodd"/>
      </g>
      <path id="Path_22750" data-name="Path 22750" d="M7026.973,11316.751h1.1v-23.687a9.5,9.5,0,0,0-1.436-5.6,4.737,4.737,0,0,0-4.043-1.867,5.33,5.33,0,0,0-5.24,3.063,11.327,11.327,0,0,0-.91,4.882h2.872v.693H7013v-.693h2.872a11.881,11.881,0,0,1,.933-5.145,5.9,5.9,0,0,1,5.791-3.4,5.323,5.323,0,0,1,4.522,2.105,10.108,10.108,0,0,1,1.556,5.958v23.688h1.053v12.131h-2.752Z" transform="translate(-6255.533 -10638.222)" fill="#c6ad9c" stroke="#c6ad9c" stroke-width="1" fill-rule="evenodd"/>
      <path id="Path_22752" data-name="Path 22752" d="M26939.723,11171.274H26937v14.37h2.723Zm0-33.138H26937v14.37h2.723Zm0-33.137H26937v14.369h2.723Z" transform="translate(-26670.709 -10502.561)" fill="#d8c4b8" fill-rule="evenodd"/>
      <g id="Group_4865" data-name="Group 4865" transform="translate(668.207 459.514) rotate(-3)">
        <g id="Group_2567" data-name="Group 2567" transform="translate(0 8.23)">
          <path id="Path_967" data-name="Path 967" d="M108.934,126.991V97.815a29.175,29.175,0,1,0,29.167,29.85Z" transform="translate(-79.758 -97.815)" fill="none" stroke="#efe0d7" stroke-width="1.5"/>
        </g>
        <g id="Group_2568" data-name="Group 2568" transform="translate(33.397 3.838)">
          <path id="Path_968" data-name="Path 968" d="M68.917,115.022l29.175.659a30.776,30.776,0,0,0-8.265-21.006A30,30,0,0,0,69.37,85.858Z" transform="translate(-68.917 -85.858)" fill="#dac6b9"/>
        </g>
      </g>
      <path id="Path_22753" data-name="Path 22753" d="M26939.723,11171.274H26937v14.37h2.723Zm0-33.138H26937v14.37h2.723Zm0-33.137H26937v14.369h2.723Z" transform="translate(-26136.836 -10506.559)" fill="#d8c4b8" fill-rule="evenodd"/>
      <g id="Group_4867" data-name="Group 4867" transform="translate(59.203 472.177)">
        <g id="Group_2576" data-name="Group 2576">
          <g id="Group_2575" data-name="Group 2575">
            <g id="Group_2574" data-name="Group 2574">
              <g id="Group_2573" data-name="Group 2573">
                <g id="Group_2572" data-name="Group 2572">
                  <g id="Group_2571" data-name="Group 2571">
                    <g id="Group_2570" data-name="Group 2570">
                      <path id="Path_969" data-name="Path 969" d="M602.431,35.273a29.968,29.968,0,0,1,7.7,2.034l5.661-4.745,9.725,7.4-3.064,6.721a29.962,29.962,0,0,1,4.015,6.883l7.356.648,1.641,12.111-6.916,2.585a29.835,29.835,0,0,1-2.036,7.7l4.745,5.662L623.861,92l-6.723-3.064a29.945,29.945,0,0,1-6.884,4.013l-.647,7.356L597.5,101.951l-2.584-6.918a29.9,29.9,0,0,1-7.7-2.034l-5.662,4.745-9.725-7.4,3.064-6.722a30,30,0,0,1-4.013-6.883l-7.355-.649-1.644-12.111,6.917-2.584a29.909,29.909,0,0,1,2.035-7.7l-4.745-5.661,7.4-9.725,6.722,3.064a29.933,29.933,0,0,1,6.884-4.014L587.734,30l12.11-1.643,2.587,6.916Zm-3.632,16.7A13.113,13.113,0,1,0,611.968,62,13.117,13.117,0,0,0,598.8,51.973Z" transform="translate(-561.874 -28.357)" fill="#fff" stroke="#9b8781" stroke-width="1" fill-rule="evenodd"/>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Magnifying_Glass" data-name="Magnifying Glass" transform="translate(255.977 318)">
        <g id="Group_3007" data-name="Group 3007" transform="translate(65 -13)">
          <g id="Group_3005" data-name="Group 3005">
            <path id="Path_1120" data-name="Path 1120" d="M121.711,299.086a17.026,17.026,0,1,1,16.941-18.835h0a17.045,17.045,0,0,1-15.119,18.738A17.219,17.219,0,0,1,121.711,299.086Zm.04-31.5a14.7,14.7,0,0,0-1.566.084,14.428,14.428,0,1,0,1.566-.084Z" transform="translate(-104.698 -265.034)" fill="#ffb971"/>
          </g>
          <g id="Group_3006" data-name="Group 3006" transform="translate(24.876 28.345)">
            <path id="Path_1121" data-name="Path 1121" d="M190.378,364.308a1.271,1.271,0,0,1-.971-.448l-16.1-18.892a1.275,1.275,0,0,1,1.94-1.654l16.1,18.892a1.275,1.275,0,0,1-.97,2.1Z" transform="translate(-173.004 -342.866)" fill="#ffb971"/>
          </g>
        </g>
      </g>
      <rect id="Rectangle_1131" data-name="Rectangle 1131" width="877" height="3" transform="translate(-32 690.424)" fill="#c6ad9c"/>
    </g>
  </g>
</svg>
 <div class="text-center" style="margin-top:4rem;">
 <a (click)="mainpage()" class="btn" style="background: #F8A05C; color:#fff; border-color: #F8A05C;">Back to home page</a>
 </div>
  
    </div>
    </div>
  </div>
  
  
  
  `,
  styles: [`
      .heading{
        margin-top: 150px auto;
      }`
  ]
})
export class Page404Component {
  
  constructor( 
    private router: Router,
    private browser: BrowserService, 
    @Inject(PLATFORM_ID) private platformId: any
  ) {
  }
  mainpage(){
    if (isPlatformBrowser(this.platformId)) {
     let city = JSON.parse(this.browser.getLocalValue('city'));
     this.router.navigateByUrl(`${city.slug}/reports`);
    }
  }
}
