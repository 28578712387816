import { isPlatformBrowser } from '@angular/common';
import { Directive,Input,ElementRef,HostListener, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[tooltip]'
})
export class TooltipDirective implements OnDestroy,OnInit {

  @Input() tooltip = ''; // The text for the tooltip to display
  @Input() delay? = 190; // Optional delay input, in ms
  @Input() position:String ='';

  private myPopup;
  private timer;

  constructor(@Inject (PLATFORM_ID) private platformId: any,
    private el: ElementRef) { }

  ngOnDestroy(): void {
    if (this.myPopup) { this.myPopup.remove() }
  }
  ngOnInit(): void {

  }

  @HostListener('mouseenter') onMouseEnter() {
    if(isPlatformBrowser(this.platformId)){
    this.timer = setTimeout(() => {
      let x = this.el.nativeElement.getBoundingClientRect().left + this.el.nativeElement.offsetWidth / 2; // Get the middle of the element
      let y = this.el.nativeElement.getBoundingClientRect().top + this.el.nativeElement.offsetHeight + 6; // Get the bottom of the element, plus a little extra
      this.createTooltipPopup(x, y);
    }, this.delay)
  }
  }

  @HostListener('mouseleave') onMouseLeave() {
    if (this.timer) clearTimeout(this.timer);
    if (this.myPopup) { this.myPopup.remove() }
  }

  private createTooltipPopup(x: number, y: number) {
    if(isPlatformBrowser(this.platformId)){
    if (this.position=='top')y = y - 50;
    if (this.position=='left'){y = y - 25; x = x - 47;}
    if (this.position=='right'){y = y - 25; x = x + 47;}
    let popup = document.createElement('div');
    popup.innerHTML = this.tooltip;
    popup.setAttribute("class", "tooltip-container");
    popup.style.top = y.toString() + "px";
    popup.style.left = x.toString() + "px";
    document.body.appendChild(popup);
    this.myPopup = popup;
    // setTimeout(() => {
    //   if (this.myPopup) this.myPopup.remove();
    // }, 5000); // Remove tooltip after 5 seconds
  }
  }
}
