import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../services/alert.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-request-demo',
  templateUrl: './request-demo.component.html',
  styleUrls: ['./request-demo.component.css'],
})
export class RequestDemoComponent implements OnInit {
  requestDemoForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private fb: FormBuilder,
    private alert: AlertService,
    private apiService: ApiService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.createRequestForDemo();
  }

  createRequestForDemo(): void {
    this.requestDemoForm = this.fb.group({
      company_name: ['', Validators.required],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', Validators.required],
      business_email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      acceptTerms: [false, Validators.requiredTrue],
    });
  }

  get requestDemo() {
    return this.requestDemoForm.controls;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.requestDemoForm.invalid) {
      return;
    }
    this.loading = true;
    this.apiService
      .createRequestAccount(this.requestDemoForm.value)
      .subscribe((response) => {
        this.loading = false;
        this.submitted = false;
        if (response?.data && response?.status === 201) {
          this.requestDemoForm.reset();
          this.alert.success(response.message, { keepAfterRouteChange: true });
          this.router.navigateByUrl('/');
        } else {
          this.alert.error(response.message)
        }
      },
      (err) => {
        this.loading = false;
        this.alert.error(err)
      });
  }
}
