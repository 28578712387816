<app-loader *ngIf="loading"></app-loader>
<div class="request-demo text_right_ar">
    <div class="requestdemo_container">
        <div class="info-col">
            <div class="info-heading">
                <h1>{{ "REQUESTDEMOACCOUNT.EXPLOREMIFORPROFESSIONAL" | translate }}</h1>
                <!-- <a target="_blank" href="https://e2.dev10.in/about-MI/mi-overview">{{ "REQUESTDEMOACCOUNT.KNOWMORE" | translate }}</a> -->

                <p>
                    {{ "REQUESTDEMOACCOUNT.PLEASESUBMIT_INFORMATION" | translate }}
                </p>

                <div class="reqestdemo_banner_image">
                    <img class="w-100 request_demo" src="../../../assets/MIAssets/Web-page/mi-dashboard.png" alt="Request Demo" />
                    <img class="w-100 request_demo_ar" src="../../../assets/MIAssets/Web-page/mi-dashboard.png" alt="Request Demo" />
                    <div class="overlay"></div>
                    <img src="../../../assets/MIAssets/Web-page/em_videoplay_icon.svg" class="btn_video_play" />
                </div>
            </div>
        </div>
        <div class="form-col">
            <div class="form_column">
                <div class="form-heading">
                    <h1>{{ "REQUESTDEMOACCOUNT.FREEDEMO" | translate }}</h1>
                </div>

                <form [formGroup]="requestDemoForm" autocomplete="off" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>{{ 'REQUESTDEMOACCOUNT.FIRSTNAME' | translate }}</mat-label>
                            <input type="text" matInput [class.is-invalid]="requestDemo.first_name.invalid && requestDemo.first_name.touched" [class.border-red]="requestDemo.first_name.invalid && requestDemo.first_name.touched" formControlName="first_name" />
                        </mat-form-field>
                        <div *ngIf="!requestDemo.first_name.valid && requestDemo.first_name.touched" style="height: 20px;margin-top:-15px;">
                            <small *ngIf="requestDemo.first_name.errors.required" class="text-danger">{{'REQUESTDEMOACCOUNT.FIRSTNAMEREQ' | translate }}</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>{{ 'REQUESTDEMOACCOUNT.LASTNAME' | translate }}</mat-label>
                            <input type="text" matInput [class.is-invalid]="requestDemo.last_name.invalid && requestDemo.last_name.touched" [class.border-red]="requestDemo.last_name.invalid && requestDemo.last_name.touched" formControlName="last_name" />
                        </mat-form-field>
                        <div *ngIf="requestDemo.last_name.errors && requestDemo.last_name.touched" style="height: 20px;margin-top:-15px;">
                            <small *ngIf="requestDemo.last_name.errors.required" class="text-danger">{{'REQUESTDEMOACCOUNT.LASTNAMEREQ' | translate }}</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>{{'REQUESTDEMOACCOUNT.BUSSEMAIL' | translate }}</mat-label>
                            <input type="email" matInput [class.is-invalid]="requestDemo.business_email.invalid && requestDemo.business_email.touched" [class.border-red]="requestDemo.business_email.invalid && requestDemo.business_email.touched" formControlName="business_email" />
                        </mat-form-field>
                        <div *ngIf="requestDemo.business_email.errors && requestDemo.business_email.touched" style="height: 20px;margin-top:-15px;">
                            <small *ngIf="requestDemo.business_email.errors.required" class="text-danger">{{'REQUESTDEMOACCOUNT.BUSSEMAILREQ' | translate }}</small>
                            <small *ngIf="requestDemo.business_email.errors.pattern" class="text-danger">{{'REQUESTDEMOACCOUNT.BUSSEMAILERR' | translate }}</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>{{'REQUESTDEMOACCOUNT.COMPNAME' | translate }}</mat-label>
                            <input type="text" matInput [class.is-invalid]="requestDemo.company_name.invalid && requestDemo.company_name.touched" [class.border-red]="requestDemo.company_name.invalid && requestDemo.company_name.touched" formControlName="company_name" />
                        </mat-form-field>
                        <div *ngIf="requestDemo.company_name.errors && requestDemo.company_name.touched" style="height: 20px;margin-top:-15px;">
                            <small *ngIf="requestDemo.company_name.errors.required" class="text-danger">{{'REQUESTDEMOACCOUNT.COMPNAMEREQ' | translate }}</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <mat-form-field class="example-full-width" appearance="fill">
                            <mat-label>{{'REQUESTDEMOACCOUNT.PHONENO' | translate }}</mat-label>
                            <input type="text" matInput [class.is-invalid]="requestDemo.phone_number.invalid && requestDemo.phone_number.touched" [class.border-red]="requestDemo.phone_number.invalid && requestDemo.phone_number.touched" formControlName="phone_number" />
                        </mat-form-field>
                        <div *ngIf="requestDemo.phone_number.errors && requestDemo.phone_number.touched" style="height: 20px;margin-top:-15px;">
                            <small *ngIf="requestDemo.phone_number.errors.required" class="text-danger">{{'REQUESTDEMOACCOUNT.PHONENOREQ' | translate }}</small>
                        </div>
                    </div>

                    <div class="form-group">
                        <div class="form_checkbox">
                            <mat-checkbox class="example-margin form-check-input" formControlName="acceptTerms" id="subscribe">
                                {{ 'REQUESTDEMOACCOUNT.AGREE' | translate }} {{ 'REQUESTDEMOACCOUNT.promotions' | translate }} <br /> {{ 'REQUESTDEMOACCOUNT.UNSUBSCRIBEMSG' | translate }} {{ 'REQUESTDEMOACCOUNT.bottom_emails' | translate }}
                            </mat-checkbox>
                            <div *ngIf="submitted && requestDemo.acceptTerms.errors" style="height: 20px">
                                <small *ngIf="requestDemo.acceptTerms.errors" class="text-danger">Accept Terms and Conditions</small>
                            </div>
                        </div>
                    </div>
                    <div class="btn_submit">
                        <button type="submit" class="btn submit mb-0">
              {{ "COMMON.SUBMIT" | translate }}
            </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="request_demo_mob_video">
        <img class="w-100 request_demo" src="../../../assets/MIAssets/Web-page/mi-dashboard.png" alt="Request Demo" />
        <img class="w-100 request_demo_ar" src="../../../assets/MIAssets/Web-page/mi-dashboard.png" alt="Request Demo" />
        <div class="overlay"></div>
        <img src="../../../assets/MIAssets/Web-page/em_videoplay_icon.svg" class="btn_video_play" />
    </div>
</div>