import { Component, Input, OnInit } from '@angular/core';
import { SubjectService } from '../../services/subject.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  lang = '1';

  @Input() width = 60;
  @Input() height = 60;
  constructor(private subject: SubjectService) { }

  ngOnInit(): void {
    this.subject.selectedLanguageForApp.subscribe((lang) => {
      this.lang = lang;
     });
  }

}
