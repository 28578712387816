<app-loader *ngIf="loading"></app-loader>
<div class="signup">
    <div class="form_column">
        <div class="form-heading mb-4">
            <h1>{{'signUp.signUp' | translate}}</h1>
        </div>
        <form [formGroup]="signUpForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label><img class="img-fluid" src="../../../assets/MIAssets/signup/admin.svg" alt="Login" class="signup-icon" /> {{'signUp.first_name' | translate}}</mat-label>
                    <input type="text" maxlength="100" formControlName="firstName" matInput required />
                </mat-form-field>
                <div *ngIf="submitted && f.firstName.errors" class="invalid-error">
                    <mat-error *ngIf="f.firstName.errors.required">{{'signUp.first_name_req' | translate}}</mat-error>
                    <!-- <mat-error *ngIf="f.firstName.errors.pattern">{{'signUp.first_name_pattern' | translate}}</mat-error> -->
                    <mat-error *ngIf="f.firstName.errors.maxlength">{{'signUp.first_name_maxlen' | translate}}</mat-error>
                    <mat-error *ngIf="f.firstName.errors.minlength">{{'signUp.first_name_minlen' | translate}}</mat-error>
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label><img class="img-fluid" src="../../../assets/MIAssets/signup/admin.svg" alt="Login" class="signup-icon" /> {{'signUp.last_name' | translate}}</mat-label>
                    <input type="text" maxlength="100" formControlName="lastName" matInput  required />
                </mat-form-field>
                <div *ngIf="submitted && f.lastName.errors" class="invalid-error">
                    <mat-error *ngIf="f.lastName.errors.required">{{'signUp.last_name_req' | translate}}</mat-error>
                    <!-- <mat-error *ngIf="f.lastName.errors.pattern">{{'signUp.last_name_pattern' | translate}}</mat-error> -->
                    <mat-error *ngIf="f.lastName.errors.maxlength">{{'signUp.last_name_maxlen' | translate}}</mat-error>
                    <mat-error *ngIf="f.lastName.errors.minlength">{{'signUp.last_name_minlen' | translate}}</mat-error>
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label><img class="img-fluid" src="../../../assets/MIAssets/signup/mail.svg" alt="Login" class="signup-icon" /> {{'signUp.email' | translate}}</mat-label>
                    <input type="text" formControlName="email"  matInput  required />
                </mat-form-field>
                <div *ngIf="submitted && f.email.errors" class="invalid-error">
                    <mat-error *ngIf="f.email.errors.required">{{'signUp.email_is_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.email.errors.email">{{'signUp.valid_email_required' | translate}}</mat-error>
                    <mat-error *ngIf="f.email.errors.maxlength">{{'signUp.valid_email_maxlen' | translate}}</mat-error>
                    <mat-error *ngIf="f.email.errors.minlength">{{'signUp.valid_email_minlen' | translate}}</mat-error>
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label><img class="img-fluid" src="../../../assets/MIAssets/signup/lock.svg" alt="Login" class="signup-icon" /> {{'signUp.password' | translate}}</mat-label>
                    <input  type="{{ passwordIcon ? 'text' : 'password'}}" formControlName="password" matInput  required />
                    <div class="eye_img" [class.eye_img_arb]="lang==2">
                        <img alt="eye closed" src="{{ passwordIcon ? '../../../assets/MIAssets/icons/eye_open_grey.svg' : '../../../assets/MIAssets/icons/eye_closed.svg'}}" (click)="toggleEyeImage('pass')" class="{{ passwordIcon ? 'open' : 'close'}}" />
                    </div>
                </mat-form-field>
                <div *ngIf="submitted && f.password.errors" class="invalid-error">
                    <div *ngIf="f.password.errors.required">{{'signUp.password_is_required' | translate}}</div>
                    <div *ngIf="f.password.errors.invalidPassword">{{'signUp.password_pattern_not_match' | translate}}</div>
                </div>
            </div>
            <div class="form-group">
                <mat-form-field class="example-full-width" appearance="fill">
                    <mat-label><img class="img-fluid" src="../../../assets/MIAssets/signup/lock.svg" alt="Login" class="signup-icon" /> {{'signUp.confirm_password' | translate}}</mat-label>
                    <input type="{{ confirmPasswordIcon ? 'text' : 'password'}}" formControlName="confirmPassword" matInput  />
                    <div class="eye_img" [class.eye_img_arb]="lang==2">
                        <img alt="eye closed" src="{{ confirmPasswordIcon ? '../../../assets/MIAssets/icons/eye_open_grey.svg' : '../../../assets/MIAssets/icons/eye_closed.svg'}}" (click)="toggleEyeImage('cpass')" class="{{ confirmPasswordIcon ? 'open' : 'close'}}" />
                    </div>
                </mat-form-field>
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-error">
                    <div *ngIf="f.confirmPassword.errors.required">{{'signUp.confirm_password_req' | translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.mustMatch">{{'signUp.confirm_password_match' | translate}}</div>
                </div>

            </div>
            <div class="text-center">
                <button type="submit" class="btn submit mb-0 mt-2">{{'signUp.signUp' | translate}}</button>

            </div>

        </form>
    </div>
</div>
