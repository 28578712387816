import {Injectable, Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'searchfilter'
})

@Injectable()
export class SearchFilterPipe implements PipeTransform {
    transform(items: any[], field: string, value: string, list:any[]): any[] {
        if (!items) return [];
        if (!value) return items;
        if(list) items = list;
        if (field) {
            return items.filter(item => item[field]?.toLowerCase().includes(value.toLowerCase())); 
        } else{ 
            return items.filter(item => item.toLowerCase().includes(value.toLowerCase()));
        }
       
    }
}
