import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BrowserService } from 'src/app/services/browser.service';
import { ApiService } from '../services/api.service';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
@Injectable()
export class CityResolver implements Resolve<any> {
  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private http: HttpClient,
    private browser: BrowserService,
    private api: ApiService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const citySlug = route.params['cid'];
    if (isPlatformBrowser(this.platformId)) {
      let city = JSON.parse(this.browser.getLocalValue('city'));
      if (!city || (city && city.slug !== citySlug)) {
        let url = `${environment.emUrl}initial-load?city_slug=${citySlug}`;
        return this.http.get(url).pipe(
          map((data: any) => {
            if (data?.data?.city) {
              this.api.citySelect(data?.data?.city, 'constructor');
              return data?.data?.city;
            }
            this.api.citySelect(citySlug, 'constructor');
            return null;
          }),
          catchError((err) => throwError(() => err))
        );
      }
      return city;
    } else {
      return null;
    }
  }
}
