<div class="main-div">
    <!-- <div class="top-section">
        <img class="close" src="assets/icons/Cross_purple.svg" alt="close" (click)="closeReset()">
        <img src="assets/icons/reset_password.svg" alt="reset password">
        <p>{{'User.resetpassword'| translate}}</p>
    </div> -->
    <form [formGroup]="forgotForm" (ngSubmit)="onSubmit(forgotForm.value)" *ngIf="visible_section == 'email_input'">
        <div class="bottom-section">
            <div class="form-group">
                <div class="input-group">
                    <span class="icon_envelope input_icon_rtl"></span>
                    <input  type="email" name="email" class="form-control input_direction reset_input" formControlName="email"
                    placeholder="{{'User.Enter_your_Email' | translate}}">
                </div>
                <mat-error class="text-left input-group" *ngIf="email.invalid && (email.dirty || email.touched)">
                    <span *ngIf="!email.errors?.required && email.errors?.pattern && email?.errors != null">
                        {{'User.valid_email' | translate}}</span>
                    <span class="text-center" *ngIf="email.errors?.required && !email.errors?.pattern && email?.errors != null">
                        {{'User.Email_required' | translate}}</span>
                </mat-error>
            </div>
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
              <button type="button" class="btn cancel" (click)="closeReset()">{{'login.go_back' |  translate}} </button>
              <button type="submit" [disabled]="submited || forgotForm.invalid" class="btn save_button">{{'User.Send_OTP'| translate}}
                <div class="button_loader" *ngIf="submited"></div></button>
            </div>
        </div>
    </form>

    <form [formGroup]="OTPForm" (ngSubmit)="SubmitOTP(OTPForm.value)" *ngIf="visible_section == 'fill_opt'">
      <div class="bottom-section">
        <div class="otp-input-wrapper">
          <app-otp-input formControlName="mail_otp" (submitevent)="SubmitOTP(OTPForm.value)"></app-otp-input>
        </div>
        <div class="resend">
          <button (click)="resendOTP()" class="onSubmit" type="button" [disabled]="button_state">
            <span class="animation_fade" *ngIf="couterdown == '0:00'">{{'User.Resend' | translate}} </span>
            <ng-container *ngIf="couterdown != '0:00'">
              <span class="animation_fade" *ngIf="couterdown != '0:00'">{{'User.ResendIn' | translate}} </span>
              <span class="animation_fade">{{ (couterdown != '0:00') ? couterdown : ''}}</span>
            </ng-container>
            <div class="button_loader" *ngIf="button_state && couterdown == '0:00'"></div>
          </button>

        </div>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
          <button type="button" class="btn cancel" (click)="back('email_input')">{{'login.go_back' |  translate}} </button>
          <button type="submit" [disabled]="loading || OTPForm.invalid" class="btn save_button">{{'User.verify'| translate}}
            <div class="button_loader" *ngIf="loading"></div>
            </button>
        </div>

      </div>
    </form>


    <form [formGroup]="newPasswordForm" (ngSubmit)="resetPassword(newPasswordForm.value)"  *ngIf="visible_section == 'new_password'">
      <div class="bottom-section">
          <div class="form-group">
              <div class="input-group">
                  <span class="icon_pass input_icon_rtl"></span>
                  <input type="password" class="form-control input_direction reset_input" formControlName="password"
                  placeholder="{{'User.Password' | translate}}">
              </div>
              <mat-error *ngIf="newPasswordForm.controls['password'].hasError('minlength'); else hasNumber">
                  {{'User.password_message2' | translate}}
              </mat-error>
              <ng-template #hasNumber>
                <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasNumber'); else hasCapitalCase">
                  {{'User.password_message3' | translate}}
                </mat-error>
              </ng-template>
              <ng-template #hasCapitalCase>
                <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasCapitalCase'); else hasSmallCase">
                  {{'User.password_message4' | translate}}
                </mat-error>
              </ng-template>
              <ng-template #hasSmallCase>
                <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasSmallCase'); else hasSpecialCharacters">
                  {{'User.password_message5' | translate}}
                </mat-error>
              </ng-template>
              <ng-template #hasSpecialCharacters>
                <mat-error *ngIf="newPasswordForm.controls['password'].hasError('hasSpecialCharacters')">
                  {{'User.password_message6' | translate}}
                </mat-error>
              </ng-template>
          </div>
          <div class="form-group">
            <div class="input-group">
              <span class="icon_pass input_icon_rtl"></span>
                <input  type="password" class="form-control input_direction reset_input" formControlName="confirmPassword"
                placeholder="{{'User.ConfirmPassword' | translate}}">
            </div>
            <mat-error *ngIf="newPasswordForm.controls['confirmPassword'].hasError('NoPassswordMatch') && newPasswordForm.value.confirmPassword.length > 0">
              {{'User.password_message7' | translate}}
            </mat-error>
          </div>

          <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px">
            <button type="button" class="btn cancel" (click)="closeReset()">{{'Profile.cancel' |  translate}} </button>
            <button type="submit" [disabled]="resetLoader || newPasswordForm.invalid" class="btn save_button">{{'User.reset'| translate}}
              <div class="button_loader" *ngIf="resetLoader"></div></button>
          </div>

      </div>
  </form>

</div>
