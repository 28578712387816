<ng-container>
    <app-loader *ngIf="loader"></app-loader>
    <div class="top-part">
        <img class="close" (click)="close()" src="assets/MIAssets/Reports/cross.svg" alt="close" />
        <div class="circle">
            <img src="assets/MIAssets/share_card.svg" alt="share_card">
        </div>
        <div class="head-sec">
            <h2> {{'REPORTS.Share_this_report' | translate}}</h2>
            <h3>{{'REPORTS.with_your_contacts' | translate}}</h3>
        </div>
    </div>
    <div class="bottom-part">
        <form>
            <!-- <div class="form-group">
                <label for="" >{{'REPORTS.Share_this_report_with' | translate}}</label>
                <input type="text" class="form-control" formControlName="email"
                    placeholder="{{'REPORTS.Mail_address' | translate}}"
                    [ngClass]="{'is-invalid': !this.shareForm.get('email').valid && this.shareForm.get('email').touched}">
            </div> -->
            <mat-form-field class="w-100">
                <mat-chip-grid #chipList>
                    <ng-container *ngFor="let email of emails">
                        <ng-container *ngIf="fnc.validateEmail(email) == true">
                            <mat-chip-row [selectable]="true" [removable]="true" (removed)="remove(email)">
                                {{email}}
                                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip-row>
                        </ng-container>
                        <ng-container *ngIf="fnc.validateEmail(email) == false">
                            <mat-chip-row class="error-chip" [selectable]="true" [removable]="true" (removed)="remove(email)" matTooltip="{{'REPORTS.mail_address_not_valid'| translate}}">
                                {{email}}
                                <mat-icon style="color: blanchedalmond;" matChipRemove *ngIf="removable">cancel</mat-icon>
                            </mat-chip-row>
                        </ng-container>
                    </ng-container>
                    <input placeholder="{{'REPORTS.Mail_address' | translate}}" #fruitInput [formControl]="mailCtrl" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="add($event)" (focusout)="addFocusOut($event)">
                </mat-chip-grid>
            </mat-form-field>
            <!-- <div *ngIf="error_messg" class="err_messg">{{'REPORTS.mail_address_not_valid'| translate}}</div> -->
            <div class="text-center">
                <button type="button" class="btn" [disabled]="error_messg" (click)="shareReport()">{{'REPORTS.Share' | translate}}</button>
            </div>
        </form>
        <div>
            <p class="share_via">{{'REPORTS.Or_Share_via' | translate}}</p>
        </div>
        <div class="social-share">
            <div (click)="copyTextToClipboard()" class="text-center pointer">
                <img src="assets/MIAssets/copy.svg" alt="copy">
                <h5>{{'REPORTS.Copy_Link' | translate}}</h5>
            </div>
            <div (click)="shareOnLinkedin()" class="text-center pointer">
                <img src="assets/MIAssets/linkedin.svg" alt="Linkedin">
                <h5>{{'REPORTS.Linkedin' | translate}}</h5>
            </div>
            <div (click)="shareOnWhatsapp()" class="text-center pointer">
                <img src="assets/MIAssets/whatsapp.svg" alt="WhatsApp">
                <h5>{{'REPORTS.WhatsApp' | translate}}</h5>
            </div>
        </div>
    </div>
</ng-container>