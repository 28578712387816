<section class="banner_design">
    <div class="container">
        <div class="row">
            <div class="w-100 intro d-flex align-items-center row_reverse_ar">
                <div fxLayout="column" class="banner-text direction_ltr_ar">
                    <span class="mb-2"> {{'about.about_banner_heading1' | translate}} {{'about.about_banner_heading2' | translate}}</span>
                </div>
                <img src="../../../../assets/MIAssets/Web-page/about_banner_illus.svg" class="img-fluid banner_image" />
                <img src="../../../../assets/MIAssets/Web-page/about_banner_illus_ar.svg" class="img-fluid banner_image banner_image_ar" />
            </div>
        </div>
    </div>
</section>

<div class="container module-container text_right_ar">
    <div class="row">
        <div class="col-md-12">
            <div class="module_heading">
                <h1>{{'about.mi_module' | translate}}</h1>
                <span class="mi-text">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum
                    has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                    galley of
                    type and scrambled it to make a type specimen book. It has survived not only five centuries, Lorem
                    Ipsum is
                    simply dummy text of the printing and typesetting industry.
                </span>
            </div>
        </div>
    </div>
</div>
<div class="module_cards text_right_ar">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h3 class="cover-text orange-text">{{'about.segment_process' | translate}}</h3>
            </div>
        </div>
        <div class="row">
            <ng-container *ngFor="let card of cards">
                <div class="col-md-4 col-sm-6">
                    <div class="box mb_4">
                        <h2 class="title">
                            {{'about.'+card.title | translate}}
                        </h2>
                        <div class="content">
                            <span class="card-content"> {{card.content}} </span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
<div class="container-fluid timeline-container text_right_ar">
    <div class="row">
        <div class="col-md-12">
            <div class="d-flex justify-content-center about_heading">
                <h1>{{'about.about_company' | translate}}</h1>
            </div>
            <div class="about-text text-center">
                <span class="">Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived
                    not only five centuries, Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry.
                </span>
            </div>
            <!-- Timeline -->
            <h1 class="about_heading2 orange-text">{{'about.from_where_it_began' | translate}}</h1>
            <br>
            <div class="process-wrapper">
                <div id="progress-bar-container">
                    <ul>
                        <li class="step step01 active">
                            <div class="step-inner"> 2011</div>
                        </li>
                        <li class="step step02">
                            <div class="step-inner">2012</div>
                        </li>
                        <li class="step step03">
                            <div class="step-inner">2013</div>
                        </li>
                        <li class="step step04">
                            <div class="step-inner">2014</div>
                        </li>
                        <li class="step step05">
                            <div class="step-inner">2015</div>
                        </li>
                        <li class="step step06">
                            <div class="step-inner">2016</div>
                        </li>
                        <li class="step step07">
                            <div class="step-inner">2017</div>
                        </li>
                        <li class="step step08">
                            <div class="step-inner">2018</div>
                        </li>
                        <li class="step step09">
                            <div class="step-inner">2019</div>
                        </li>
                        <li class="step step10">
                            <div class="step-inner">2020</div>
                        </li>
                    </ul>
                    <div id="line">
                        <div id="line-progress"></div>
                    </div>
                </div>

                <div id="progress-content-section">
                    <div class="section-content 2011 active">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2012">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>

                    <div class="section-content 2013">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>

                    <div class="section-content 2014">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2015">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2016">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2017">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2018">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2019">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                    <div class="section-content 2020">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit duis cum, nec libero elementum magnis
                            risus etiam viverra curabitur nostra ad, class convallis lobortis pretium urna hendrerit
                            tempus eros.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container other-info text_right_ar">
    <div class="row">
        <div class="col-md-12">
            <div class="info info_card d-flex align-items-center mb-5">
                <div class="col-md-2">
                    <h3>{{'about.our_approach' | translate}}</h3>
                </div>
                <div class="col-md-10">
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type.</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info info_card d-flex align-items-center mb-5">
                <div class="col-md-2">
                    <h3>{{'about.our_clients' | translate}}</h3>
                </div>
                <div class="col-md-10">
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type.</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info info_card d-flex align-items-center mb-5">
                <div class="col-md-2">
                    <h3>{{'about.our_people' | translate}}</h3>
                </div>
                <div class="col-md-10">
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type.</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info info_card d-flex align-items-center mb-5">
                <div class="col-md-2">
                    <h3>{{'about.business_practice' | translate}}</h3>
                </div>
                <div class="col-md-10">
                    <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type.</span>
                </div>
            </div>
        </div>
        <div class="col-md-12">
            <div class="info info_card d-flex align-items-center mb-5">
                <div class="col-md-2">
                    <h3>{{'about.work_experience' | translate}}</h3>
                </div>
                <div class="col-md-10">
                    <span>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                        been the industry's standard dummy text ever since the 1500s, when an unknown printer took a
                        galley of type.
                    </span>
                </div>
            </div>
        </div>
    </div>
</div>