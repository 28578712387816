import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../../services/api.service';
import { MustMatch, passwordValidator } from '../../shared/_helpers/validator';
import { AlertService } from '../../services/alert.service';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signUpForm: FormGroup;
  submitted = false;
  loading = false;
  lang;
  passwordIcon = false;
  confirmPasswordIcon = false;

  @Output() changeLoginForm = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private alert: AlertService
  ) {}

  ngOnInit(): void {
    this.lang = this.apiService.language;
    this.signUpFormControl();
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.signUpForm.controls;
  }

  signUpFormControl(): void {
    this.signUpForm = this.formBuilder.group(
      {
        firstName: ['', Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2)])],
        lastName: ['', Validators.compose([Validators.required, Validators.maxLength(45), Validators.minLength(2)])],
        email: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(100), Validators.minLength(6)])],
        password: ['', Validators.compose([Validators.required, passwordValidator()])],
        confirmPassword: ['', Validators.required],
      },
      {
        validator: MustMatch('password', 'confirmPassword'),
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    const value = this.signUpForm.value;

    if (this.signUpForm.valid) {
      this.loading = true;
      const body = {
        user_email: value.email,
        password: value.password,
        attributes: {
          [this.apiService.language]: { first_name: value.firstName, last_name: value.lastName },
        },
      };

      this.apiService.postUmsData(`register`, body).subscribe((res: any) => {
        this.loading = false;
        if (res && res.status == 201) {
          this.changeLoginForm.next(true);
          this.alert.success(res.message);
        } else {
          this.alert.error(res.message);
        }
      });
    }
  }

  toggleEyeImage(val){
    if(val === 'pass'){
      this.passwordIcon = !this.passwordIcon;
    } else {
      this.confirmPasswordIcon = !this.confirmPasswordIcon
    }
  }
}
