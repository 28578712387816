import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[onlyNumeric]',
})
export class OnlyNumericeDirective {
  private specialKeys: Array<string> = [
    'Backspace',
    'Tab',
    'End',
    'Home',
    'ArrowLeft',
    'ArrowRight',
    'Del',
    'Delete',
  ];

  constructor() {}

  @HostListener('keydown', ['$event'])
  onkeyDown(event: KeyboardEvent) {
    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.key === ' ' || isNaN(Number(event.key))) {
      event.preventDefault();
    }
  }
}
