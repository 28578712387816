import { Component, Inject, OnInit, Output, Input, EventEmitter, ViewChild, NgZone } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-dialogbox',
  templateUrl: 'confirm-dialogbox.html',
  styleUrls: ['confirm-dialogbox.scss']
})
export class ConfirmDialogBox {

  message: string = '';
  class='';
  type:string='';
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogBox>,
    @Inject(MAT_DIALOG_DATA) public info: any) {
    this.message = this.info.message;
    this.class = this.info.class
    this.type= this.info.type?this.info.type:'';
   }

  confirmDialogBox(check:any) {
    this.dialogRef.close(check);
  }

}