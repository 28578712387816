<section class="contact_sec banner_design direction_ltr_ar">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div class="w-100 intro d-flex align-items-center row_reverse_ar">
                    <div fxLayout="column" class="banner-text direction_ltr_ar">
                        <span class="mb-2"> {{'contact.contact_banner_heading1' | translate}} {{'contact.contact_banner_heading2' | translate}}</span>
                    </div>
                    <img src="../../../../assets/MIAssets/Web-page/contact_banner_illus.svg" class="img-fluid banner_image" />
                    <img src="../../../assets/MIAssets/Web-page/contact_banner_illus_ar.svg" class="img-fluid banner_image banner_image_ar" />
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact_content text_right_ar">
    <div class="contact_form">
        <div class="form_inner">
            <div class="form-heading">
                <h1>{{'contact.get_in_touch' | translate}}</h1>
            </div>
            <form [formGroup]="contactForm" autocomplete="off">
                <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{'contact.name' | translate}}</mat-label>
                        <input type="text" matInput formControlName="name" />
                    </mat-form-field>
                    <div *ngIf="!contactForm.controls.name.valid && contactForm.controls.name.touched" style="height: 20px;margin-top:-15px;">
                        <small *ngIf="contactForm.controls.name.errors.required" class="text-danger">{{'contact.name_required' | translate}}</small>
                    </div>
                </div>
                <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{'contact.email' | translate}}</mat-label>
                        <input type="email" matInput formControlName="email" />
                    </mat-form-field>

                    <div *ngIf="!contactForm.controls.email.valid && contactForm.controls.email.touched" style="height: 20px;margin-top:-15px;">
                        <small *ngIf="contactForm.controls.email.errors.required" class="text-danger">{{'contact.email_required' | translate}}</small>
                        <small *ngIf="contactForm.controls.email.errors.pattern" class="text-danger">{{'contact.valid_email_required' | translate}}</small>
                    </div>

                </div>
                <div class="form-group ">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{'contact.subject' | translate}}</mat-label>
                        <input type="email" matInput formControlName="subject" />
                    </mat-form-field>
                    <div *ngIf="!contactForm.controls.subject.valid && contactForm.controls.subject.touched" style="height: 20px;margin-top:-15px;">
                        <small *ngIf="contactForm.controls.subject.errors.required" class="text-danger">{{'contact.subject_required' | translate}}</small>
                    </div>

                </div>
                <div class="form-group">
                    <mat-form-field class="example-full-width" appearance="fill">
                        <mat-label>{{'contact.message' | translate}}</mat-label>
                        <input type="text" matInput formControlName="message" />
                    </mat-form-field>
                    <div *ngIf="!contactForm.controls.message.valid && contactForm.controls.message.touched" style="height: 20px;margin-top:-15px;">
                        <small *ngIf="contactForm.controls.message.errors.required" class="text-danger">{{'contact.message_required' | translate}}</small>
                    </div>


                </div>
                <div>
                    <button type="submit" [disabled]="!contactForm.valid || btnDisable" class="btn submit" (click)="contactSubmit()">{{'contact.submit' | translate}}</button>
                </div>
            </form>
        </div>
        <div class="newsletter">
            <div class="newletter_container">
                <div class="subscibe-info">
                    <h3>{{'contact.subscribe_newsletter' | translate}}</h3>
                    <span class="subscribe-text">
                        {{'contact.newsletter_content1' | translate}}
                        {{'contact.newsletter_content2' | translate}}
                    </span>
                </div>
                <div class="d-flex align-items-center mt-3 mb-3 subs-info">
                    <input type="email" class="subscribe-email" placeholder="{{'contact.enter_your_emailaddress' | translate}}">
                    <div class="btn_subscribe">
                        <button class="btn subscribe-btn">{{'contact.btn_subscribenow' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="contact_address">
        <div class="contact_container">
            <div class="info-details call">
                <img height="45" width="45" [class]="selectedLangCode =='en' ? 'mr-4' : 'ml-4'" src="../../../assets/MIAssets/Web-page/contact/call.svg" alt="Call">
                <div class="number ml-2">
                    <span>+965 9946 2912</span>
                    <span>+973 3698 7855</span>
                </div>
            </div>
            <div class="info-details email">
                <img height="45" width="45" [class]="selectedLangCode =='en' ? 'mr-4' : 'ml-4'" src="../../../assets/MIAssets/Web-page/contact/mail.svg" alt="Mail" />
                <span class="ml-2 mt-2">info@estater.com</span>
            </div>
            <div class="location">
                <img height="45" width="45" [class]="selectedLangCode =='en' ? 'mr-4' : 'ml-4'" src="../../../assets/MIAssets/Web-page/contact/location.svg" alt="Location" />
                <div class="all">
                    <div class="india ml-2 mb-4 mt-2">
                        <span class="country">India</span>
                        <span class="address mt-2">{{'contact.address_india' | translate}}</span>
                    </div>
                    <div class="kuwait ml-2 mb-4">
                        <span class="country mb-4">Kuwait</span>
                        <span class="address mt-2">{{'contact.address_kuwait' | translate}}</span>
                    </div>
                    <div class="bahrain ml-2 mb-4">
                        <span class="country mb-4">Bahrain</span>
                        <span class="address mt-2">{{'contact.address_bahrain' | translate}}</span>
                    </div>
                </div>
            </div>
            <div class="follow">
                <img height="45" width="45" [class]="selectedLangCode =='en' ? 'mr-4' : 'ml-4'" src="../../../assets/MIAssets/Web-page/contact/follow_us.svg" class="img-fluid" />
                <div class="all">
                    <h2>{{'contact.follow_us' | translate}}</h2>
                    <span class="follow-text mb-2">
                        {{'contact.follow_content1' | translate}}
                        {{'contact.follow_content2' | translate}}</span>
                    <ul class="follow_socialmedia">
                        <li>
                            <a href="https://www.facebook.com/estater/" target="_blank">
                                <i class="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/Estater_bits" target="_blank">
                                <i class="fa fa-twitter"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/estater/" target="_blank">
                                <i class="fa fa-linkedin"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://blog.estater.com/" target="_blank">
                                <img height="45" width="45" [src]="imgSrc" (mouseover)="onMouseOver()" (mouseout)="onMouseOut()" />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="newsletter news_mob">
        <div class="subscibe-info">
            <h3>{{'contact.subscribe_newsletter' | translate}}</h3>
            <span class="subscribe-text">
                {{'contact.newsletter_content1' | translate}}
                {{'contact.newsletter_content2' | translate}}
            </span>
        </div>
        <div class="d-flex align-items-center mt-3 mb-3 subs-info">
            <input type="email" class="subscribe-email" placeholder="Enter your email address">
            <div class="btn_subscribe">
                <button class="btn subscribe-btn">{{'contact.btn_subscribenow' | translate}}</button>
            </div>
        </div>
    </div>
</section>