import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-section',
  templateUrl: './footer-section.component.html',
  styleUrls: ['./footer-section.component.css']
})
export class FooterSectionComponent implements OnInit {
  public year = new Date().getFullYear();
  public isLogin_requestdemo:boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry,
    private route:Router
  ) {
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/MI Assets/facebook.svg'))
    iconRegistry.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('assets/MI Assets/twitter.svg'))
    iconRegistry.addSvgIcon('twitter', sanitizer.bypassSecurityTrustResourceUrl('assets/MI Assets/linkdin.svg'))

    route.events.subscribe((url:any) => {
      if(url.url !=undefined && url.url == '/request-demo' || url.url !=undefined && url.url == '/'){
        this.isLogin_requestdemo = true;
      }
      else{
        this.isLogin_requestdemo = false;
      }
    });
  }

  ngOnInit(): void {  
  }
}
