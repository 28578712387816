<button mat-icon-button [matMenuTriggerFor]="notification" (menuClosed)="menuClosed()">
    <mat-icon *ngIf="unreadCount == 0" svgIcon="bell-icon" title="Notifications">
    </mat-icon>
    <mat-icon style="font-family: 'Open Sans';" *ngIf="unreadCount != 0" svgIcon="bell-icon" title="Notifications"
        [matBadge]="unreadCount" [matBadgeHidden]="unreadCount == 0" matBadgeColor="warn" matBadgeSize="small"
        matTooltip={{unreadCount}}>
    </mat-icon>
    <!-- <mat-icon style="font-family: 'Open Sans';" svgIcon="bell-icon" title="Notifications" [matBadge]="unreadCount"
        [matBadgeHidden]="unreadCount == 0" matBadgeColor="warn" matBadgeSize="small" matTooltip={{unreadCount}}>
    </mat-icon> -->
    <!-- Include text description of the icon's meaning for screen-readers -->
</button>
<mat-menu #notification="matMenu" class="mat-menu-notify" xPosition="after">
    <ng-template matMenuContent>
        <div [dir]="lang === 2 ? 'rtl' : 'ltr'" (click)="$event.stopPropagation()">
            <div class="d-flex justify-content-between align-items-center py-3 px-4">
                <h4 class="m-0">{{'notification.heading' | translate}}</h4>
                <div class="d-flex justify-content-between align-items-center">
                    <span class="mx-3" style="font-size: 13px;">{{'notification.show_unread' | translate}}</span>
                    <mat-slide-toggle (change)="showAllUnread($event)" (click)="$event.stopPropagation()" [checked]="showOnlyUnread"></mat-slide-toggle>
                </div>
            </div>
            <hr class="border_nm" *ngIf="!isUserAdmin">
            <mat-tab-group animationDuration="0ms" mat-align-tabs="start" *ngIf="isUserAdmin">
                <ng-container *ngFor="let tab of tabs">
                    <mat-tab label="{{tab.label}}">
                        <div class="w-100 py-2 px-4" [class.text-right]="lang != 2" [class.text-left]="lang === 2">
                            <a class="markAllRead open-sans" (click)="markRead(null,$event,true)" *ngIf="tab.data.length">{{'notification.mark_all_read' | translate}}</a>
                        </div>
                        <section class="notifications" [class.no-results]="!tab.data.length && showOnlyUnread==true">
                            <ng-container *ngIf="tab.data.length && !loader">
                                <ng-container *ngFor="let item of tab.data | slice : 0 : 10">
                                    <article class="notification-list" [ngClass]="{'unread': item.is_read == false}" *ngIf="item.msg_body" (click)="markRead(item,$event)" style="border-bottom:1px solid #C3B3D1;">
                                        <a class="redirect-notif" *ngIf="item.notification_detail && item.notification_detail.url" [href]="redirectEnvUrl + item.notification_detail.url" target="_blank" matTooltip="Open User Subscription" matTooltipPosition="right"></a>
                                        <div class="d-flex justify-content-between notification">
                                            <img *ngIf="item.profile_image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40" [src]="item.profile_image">
                                            <img *ngIf="!item.profile_image" src="../../../assets/MIAssets/icons/user_login.svg" alt="Profile Image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40">
                                            <div class="d-flex">
                                                <div class="d-flex flex-column w-100" [class.text-left]="lang != 2" [class.text-right]="lang === 2">
                                                    <span [innerHtml]="item?.msg_body" style="font-size: 12px;white-space:pre-wrap" class="open-sans"></span>
                                                    <span class="mt-1 mb-2" style="font: normal normal 600 11px/14px Open Sans;
                                                        letter-spacing: 0px;color: #A8A8A8;opacity: 1;">{{item.notif_date_send}}
                                                    </span>
                                                    <span class="mb-2" style="font: normal normal normal 11px/14px Open Sans;
                                                    letter-spacing: 0px;color: #A8A8A8;opacity: 1;">
                                                    Last Seen By :  <span class="font-weight-bold">{{item.last_read}}</span>
                                                    </span>
                                                    <!-- <hr class="notify-border"> -->
                                                </div>
                                                <!-- <button [class.mark-read]="item.is_read == false" [class.mark-already-read]="item.is_read == true">
                                                    <div class="unread-indicator" *ngIf="item.is_read==false"></div>
                                                </button> -->
                                            </div>
                                        </div>
                                    </article>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="loader">
                                <ng-container *ngFor="let b of generateFake(4)">
                                    <div class="first-section-wrapper padding">
                                        <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }">
                                        </ngx-skeleton-loader>
                                        <div class="titlee">
                                            <div>
                                                <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{
                                                    width: '234px',
                                                    'border-radius': '0',
                                                    height: '15px',
                                                    'margin-bottom': '10px'
                                                  }"></ngx-skeleton-loader>
                                            </div>
                                            <div>
                                                <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                                                </ngx-skeleton-loader>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="!tab.data.length && showOnlyUnread==true && !loader">
                                <img height="200" width="200" src="../../../assets/MIAssets/question.png" alt="No Results Found">
                                <p class="my-3">{{'notification.no_unread' | translate}}</p>
                            </ng-container>
                        </section>
                    </mat-tab>
                </ng-container>
            </mat-tab-group>
            <!-- for normal user -->
            <ng-container *ngIf="!isUserAdmin">
                <section style="list-style:none!important;padding:0px!important;margin: 0px!important;">
                    <div class="w-100 py-2 px-4" [class.text-right]="lang != 2" [class.text-left]="lang === 2">
                        <a class="markAllRead" (click)="markRead(null,$event,true)" *ngIf="notifications.length">{{'notification.mark_all_read' | translate}}</a>
                    </div>
                    <section class="notifications" [class.no-results]="!notifications.length && showOnlyUnread==true">
                        <ng-container *ngIf="notifications.length && !loader">
                            <ng-container *ngFor="let item of notifications | slice : 0 : 10">
                                <article class="notification-list" [ngClass]="{'unread': item.is_read == false}" *ngIf="item.msg_body" (click)="markRead(item,$event)" style="border-bottom:1px solid #C3B3D1;">
                                    <a class="redirect-notif" *ngIf="item.notification_detail && item.notification_detail.url" [href]="redirectEnvUrl + item.notification_detail.url" target="_blank" matTooltip="Open User Subscription" matTooltipPosition="right"></a>
                                    <div class="d-flex justify-content-between notification">
                                        <img *ngIf="item.profile_image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40" [src]="item.profile_image">
                                        <img *ngIf="!item.profile_image" src="../../../assets/MIAssets/icons/user_login.svg" alt="Profile Image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40">
                                        <div class="d-flex">
                                            <div class="d-flex flex-column w-100" [class.text-left]="lang != 2" [class.text-right]="lang === 2">
                                                <span [innerHtml]="item?.msg_body" style="font-size: 12px;" class="open-sans"></span>
                                                <span class="mt-1 mb-2" style="font: normal normal 600 11px/14px Open Sans;
                                                    letter-spacing: 0px;color: #A8A8A8;opacity: 1;">{{item.notif_date_send}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="loader">
                            <ng-container *ngFor="let b of generateFake(4)">
                                <div class="first-section-wrapper padding">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }">
                                    </ngx-skeleton-loader>
                                    <div class="titlee">
                                        <div>
                                            <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{
                                                width: '234px',
                                                'border-radius': '0',
                                                height: '15px',
                                                'margin-bottom': '10px'
                                              }"></ngx-skeleton-loader>
                                        </div>
                                        <div>
                                            <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!notifications.length && showOnlyUnread==true && !loader">
                            <img height="200" width="200" src="../../../assets/MIAssets/question.png" alt="No Results Found">
                            <p class="my-3">{{'notification.no_unread' | translate}}</p>
                        </ng-container>
                    </section>
                    <!-- normal user end -->
                    <!-- <section class="notification-padding" [class.no-results]="!notifications.length && showOnlyUnread==true">
                        <ng-container *ngIf="notifications.length && !loader">
                            <ng-container *ngFor="let item of notifications | slice : 0 : 10">
                                <article class="notification-list" *ngIf="item.msg_body" (click)="markRead(item,$event)">
                                    <a class="redirect-notif" *ngIf="item.notification_detail && item.notification_detail.url" [href]="redirectEnvUrl + item.notification_detail.url" target="_blank" matTooltip="Open User Subscription" matTooltipPosition="right"></a>
                                    <div class="d-flex justify-content-between notification">
                                        <img *ngIf="item.profile_image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40" [src]="item.profile_image">
                                        <img *ngIf="!item.profile_image" src="../../../assets/MIAssets/icons/user_login.svg" alt="Profile Image" class="img-shadow rounded ng-star-inserted" height="40" [class.mr-4]="lang != 2" [class.ml-4]="lang === 2" style="border-radius:50% !important;" width="40">
                                        <div class="d-flex" style="border-bottom: 1px solid #E2E4E3;">
                                            <div class="d-flex flex-column w-100" [class.text-left]="lang != 2" [class.text-right]="lang === 2">
                                                <span [innerHtml]="item?.msg_body" style="font-size: 12px;"></span>
                                                <span class="mt-1 mb-2" style="font: normal normal 600 11px/14px Open Sans;
                                                letter-spacing: 0px;color: #A8A8A8;opacity: 1;">{{item.notif_date_send}}
                                                </span>

                                            </div>
                                            <button [class.mark-read]="item.is_read == false" [class.mark-already-read]="item.is_read == true">
                                                <div class="unread-indicator" *ngIf="item.is_read==false"></div>
                                            </button>
                                        </div>
                                    </div>
                                </article>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="loader">
                            <ng-container *ngFor="let b of generateFake(4)">
                                <div class="first-section-wrapper">
                                    <ngx-skeleton-loader appearance="circle" [theme]="{ width: '40px', height: '40px' }">
                                    </ngx-skeleton-loader>
                                    <div class="titlee">
                                        <div>
                                            <ngx-skeleton-loader *ngIf="!contentLoaded" [theme]="{
                                            width: '234px',
                                            'border-radius': '0',
                                            height: '15px',
                                            'margin-bottom': '10px'
                                          }"></ngx-skeleton-loader>
                                        </div>
                                        <div>
                                            <ngx-skeleton-loader [theme]="{ width: '170px', 'border-radius': '0', height: '15px' }">
                                            </ngx-skeleton-loader>
                                        </div>
                                    </div>

                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!notifications.length && showOnlyUnread==true && !loader">
                            <img height="200" width="200" src="../../../assets/MIAssets/question.png" alt="No Results Found">
                            <p class="my-3">{{'notification.no_unread' | translate}}</p>
                        </ng-container>
                    </section> -->
                </section>
            </ng-container>
            <section style="list-style:none!important;padding:0px!important;margin: 0px!important;">
                <div class="w-100 d-flex justify-content-center align-items-center font-weight-bold see-all">
                    <a [routerLink]="['/user/notification-details']" class="notificatin_all_activity">
                        {{'notification.see_all_incoming' | translate}}
                    </a>
                </div>
            </section>
        </div>
    </ng-template>
</mat-menu>