import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor() { }
  cards: any = [
    {
      title: "comprehensive_research",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "financial_research",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "industry_research",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "company_research",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "market_capital",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "governance_value",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "gio_analytics",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "development_advisory",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },
    {
      title: "risk_management",
      content: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry."
    },

  ]
  ngOnInit(): void {
    $(".step").click(function () {
      $(this).addClass("active").prevAll().addClass("active");
      $(this).nextAll().removeClass("active");
    });

    $(".step01").click(function () {
      $("#line-progress").css("width", "5%");
      $(".2011").addClass("active").siblings().removeClass("active");
    });

    $(".step02").click(function () {
      $("#line-progress").css("width", "15%");
      $(".2012").addClass("active").siblings().removeClass("active");
    });

    $(".step03").click(function () {
      $("#line-progress").css("width", "25%");
      $(".2013").addClass("active").siblings().removeClass("active");
    });

    $(".step04").click(function () {
      $("#line-progress").css("width", "35%");
      $(".2014").addClass("active").siblings().removeClass("active");
    });
    $(".step05").click(function () {
      $("#line-progress").css("width", "45%");
      $(".2015").addClass("active").siblings().removeClass("active");
    });
    $(".step06").click(function () {
      $("#line-progress").css("width", "55%");
      $(".2016").addClass("active").siblings().removeClass("active");
    });
    $(".step07").click(function () {
      $("#line-progress").css("width", "65%");
      $(".2017").addClass("active").siblings().removeClass("active");
    });

    $(".step08").click(function () {
      $("#line-progress").css("width", "75%");
      $(".2018").addClass("active").siblings().removeClass("active");
    });

    $(".step09").click(function () {
      $("#line-progress").css("width", "85%");
      $(".2019").addClass("active").siblings().removeClass("active");
    });

    $(".step10").click(function () {
      $("#line-progress").css("width", "100%");
      $(".2020").addClass("active").siblings().removeClass("active");
    });
  }

}
