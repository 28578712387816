import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatModules } from '../mat-modules';
import { I18nModule } from '../i18n/i18n.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, ...MatModules, I18nModule],
  exports: [],
})
export class ShareModule {}
