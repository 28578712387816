import { Component } from '@angular/core';
import { ApiService } from './services/api.service';
import { AutologoutService } from './services/autologout.service';
import { BrowserService } from './services/browser.service';
import { map } from 'rxjs';
import { MediaChange, MediaObserver } from '@angular/flex-layout';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mi-ang';
  constructor(
    private apiService: ApiService,
    private autoLogout: AutologoutService,
    private browser: BrowserService,
    private media: MediaObserver,

  ) {
    
    this.setLangHtmlTag();
    
  }

  ngOnInit(){
    this.apiService.setUserLocation();
    this.media.asObservable().pipe(map((change: MediaChange[]) =>{
      if (change[0].mqAlias == 'sm') {
        this.apiService.mobile = true;
      }
      if (change[0].mqAlias == 'xs') {
        this.apiService.mobile = true;
      } else {
        this.apiService.mobileview = false;
      }
    }));

  }

  autoLogoutApp() {
    this.autoLogout.resetTimer();
  }

  setLangHtmlTag(){
    const lang =  this.browser.getLocalValue('selected_language') ? JSON.parse(this.browser.getLocalValue('selected_language')).language_code : '';

    if(lang && lang === 'en'){
      document.getElementsByTagName('html')[0].removeAttribute('dir');
    } else if(lang && lang === 'ar'){
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
  }
}
