import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, PLATFORM_ID,Inject } from '@angular/core';
import { BrowserService } from '../../../services/browser.service';
declare const L: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  userInfo;
  lt;
  ln;

  // location = {
  //   lat: 29.359766,
  //   lng: 47.987084
  // }
  map: any;
  latlon: any[];
  mapMarker: any;
  draggable: boolean;
  _latlon:any=[];

  constructor(private browser:BrowserService,
    @Inject(PLATFORM_ID) private platformId:any) {
    this.userInfo = JSON.parse(this.browser.getLocalValue('user_info'));
    if (this.userInfo.latitude && this.userInfo.longitude) {
      this.latlon = [this.userInfo.latitude, this.userInfo.longitude]

    }
    else {
      this.lt = 29.359766;
      this.ln = 47.987084;
    }
  }

  ngOnInit(): void {
    this.renderMap();
  }

  ngAfterViewInit() {
    if(isPlatformBrowser(this.platformId)){
      this.renderMap();
    }
  }

    /**
   * This function use to create a map view and set lan, lon, marker
  
   * @returns return Void
   */
  renderMap(): void {
      let mymap = L.map('map').setView(this.latlon, 13);
      this.map = mymap;
      mymap.removeControl(mymap.zoomControl);
      L.tileLayer('https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {
        zoom: 12,
        zoomControl: false,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
      }).addTo(mymap);
      this.panlocation();
  }

    /**
   * This is a function that used to pan to map on given lat lon.
  
   * @version 8.9.0
   * @returns return is void
   */
  panlocation(){
    if (this.latlon) {
      let latlon = this.latlon;
      this.map.setView(latlon, 12);
      var greenIcon = L.icon({
        iconUrl: 'assets/leaflet/images/marker.png',
        iconSize: [25, 41],
      });
      let that = this;
      if(this.mapMarker){
        this.map.removeLayer(this.mapMarker);
      }
      this.mapMarker = L.marker(latlon, { icon: greenIcon, draggable: this.draggable })
        // .on('click', function (e: any) {
        //   L.popup()
        //     .setLatLng(latlon).
        //     .openOn(that.map);
        // })
        .on('dragend', function (e:any) {
          var coord = String(that.mapMarker.getLatLng()).split(',');
          var lat = coord[0].split('(');
          var lng = coord[1].split(')');          
          that._latlon = [parseFloat(lat[1]), parseFloat(lng[0])];
        })
        .addTo(this.map);
    }
  }
 
}
