export const reportsConstants = {
  stepNormal: 'normal',
  stepActive: 'active',
  stepSelected: 'selected',
  reportsMenu: [
    {
      step: 0,
      name: 'REPORTS.OBJECTIVE',
      code: 'objective',
      icon: '../../../assets/MIAssets/Reports/Union 40.svg',
      activeicon: '../../../assets/MIAssets/Reports/Union40-active.svg',
      background: 'normal', // normal , active, selected
    },
    {
      step: 1,
      name: 'REPORTS.STUDY',
      code: 'group',
      icon: '../../../assets/MIAssets/Reports/land.svg',
      activeicon: '../../../assets/MIAssets/Reports/land-active.svg',
      background: 'normal', // normal , active, selected
    },
    {
      step: 2,
      name: 'REPORTS.VIEWQUERIES',
      code: 'report',
      icon: '../../../assets/MIAssets/Reports/goal.svg',
      activeicon: '../../../assets/MIAssets/Reports/goal-active.svg',
      background: 'normal', // normal , active, selected
    },
    {
      step: 3,
      name: 'REPORTS.GENERATE',
      icon: '../../../assets/MIAssets/Reports/file.svg',
      activeicon: '../../../assets/MIAssets/Reports/file-active.svg',
      background: 'normal', // normal , active, selected
    },
  ],
  barReportsSetting: {},
};

export const actionIdConstants = {
  Add_Objectives: 1,
  Add_Groups: 2,
  Add_Reports: 3,
  Update_Objectives: 4,
  Update_Groups: 5,
  Update_Reports: 6,
  Profile_Update: 7,
  Sync_Process: 8,
  Csv_Upload: 9,
  View_Objective: 10,
  View_Group: 11,
  View_Report: 12,
  Csv_Template: 14,
  User_Login: 13,
  Download_Report_Pdf: 15,
  Share_Report: 16,
  User_Logout: 17,
  Save_Report: 18,
  Add_Subscription_Master: 19,
  Update_Subscription_Master: 20,
  Add_User_Subscription: 21,
  Update_User_Subscription: 22,
  Add_Search_Master: 23,
  Update_Search_Master: 24,
  Delete_Search_Master: 25,
  Add_Parameter_Master: 26,
  Update_Parameter_Master: 27,
  Delete_Parameter_Master: 28,
  Update_Report_Filter: 29,
  Setting_Update: 30,
  Package_Plan_Request: 31,
  Add_Project: 32,
  Update_Project: 33,
  Share_Project: 34,
  Un_share_project: 35
};

export const reportList = {
  units: ['total_property', 'total_unit', 'average_monthly_rent'],
  inventory: [
    'total_property',
    'total_unit',
    'occupancy_ratio',
    'occupied_unit',
    'vacant_unit',
    'average_monthly_rent',
  ],
  units_occupancy: [
    'total_property',
    'total_unit',
    'occupancy_ratio',
    'average_monthly_rent',
  ],
  property_profiling: [
    'total_property',
    'total_unit',
    'average_number_unit',
    'total_parking',
    'average_land_area',
    'parking_ratio_unit',
  ],
  population: ['total_population', 'male', 'female'],
  mallInventory: [
    'total_unit',
    'gross_leasable_area',
    'occupancy',
    'vacant_area',
  ],
  mallProfiling: [
    'land_area',
    'total_buildup_area',
    'gross_leasable_area',
    'no_of_floor',
    'total_retail_unit',
    'total_anchor_tenant',
    'total_car_parking',
    'occupancy',
  ],
  mallTentant: ['total_units', 'gross_leasable_area', 'avg_lease_area'],
  officeOccupancy: [
    'total_property',
    'gross_leasable_area',
    'occupancy',
    'average_lease_rate',
  ],
  officeSpace: [
    'total_property',
    'gross_leasable_area',
    'occupied_area',
    'occupancy_per',
    'average_lease_rate',
  ],
  officeProfiling: [
    'land_area',
    'no_of_floor',
    'no_car_parking',
    'gross_leasable_area',
    'office_gross_leasable_area',
    'office_occupied_area',
    'office_avg_lease_rate',
    'office_no_of_floor',
    'retail_gross_leasable_area',
    'retail_occupied_area',
    'retail_avg_lease_rate',
    'retail_no_of_floor',
  ],
  retailfb: ['no_of_retail_unit', 'gross_leasable_area', 'avg_unit_area'],
  employmentData: [
    'employment',
    'office_demand',
    'govern_office_demand',
    'private_office_demand',
  ],
  landVacancy: [
    'no_of_parcel',
    'no_of_developed_parcel',
    'no_of_vacant_parcel',
    'area_of_parcel',
    'area_of_developed_parcel',
    'area_of_vacant_parcel',
  ],
  landmaster: [
    'no_of_parcel',
    'no_of_developed_parcel',
    'no_of_vacant_parcel',
    'area_of_parcel',
    'area_of_developed_parcel',
    'area_of_vacant_parcel',
  ],
  roadData: ['area', 'length'],
  underConstruction: [
    'no_of_properties',
    'land_area',
    'built_up_area',
    'no_of_car_parking',
  ],
};

export const tableColumn = {
  normalPieColumn: ['legends', 'category', 'share'],
  populationPieColumn: ['legends', 'age', 'share'],
  unitUnitType: ['legends', 'unit_type_wise', 'share'],
  retailAreaCategory: ['legends', 'sub_category', 'share'],
  cusineWiseChart: ['legends', 'cuisine', 'share'],
  landVacancyZoningShareColumn: ['legends', 'category', 'share'],
  shareOfTenantCategories: ['legends', 'category', 'share'],
  officeGradeWise: ['legends', 'grade', 'share'],
  shareOfParking: ['legends', 'parking_type', 'share'],
  unitGrade: ['legends', 'grade', 'share'],
  unitFurnishing: ['legends', 'furnishing_status', 'share'],
  commonColumn: ['neigh_name', 'unit_type_wise'],
  population: ['neigh_name', 'age_group'],
  mallInventory: ['neigh_name'],
  mallProfiling_floorWise: [
    'floors',
    'gross_leasable_area',
    'no_of_tenants',
    'total_anchor_tenant',
  ],
  mallTentant: [
    'tanent_name',
    'property_name',
    'floor',
    'tanent_category',
    'sub_category',
  ],
  mall_Tentant: {
    column: [
      'tanent_name',
      'property_name',
      'floor',
      'tanent_category',
      'sub_category',
    ],
    data: [
      'tanent_name',
      'property_name',
      'floor',
      'tanent_category',
      'sub_category',
    ],
  },
  officePropertyGrade: ['area_range'],
  officeProfiling: ['property_name', 'floors', 'tenent_name', 'leasable_area'],
  mallTentantLease: [
    'property_type',
    'tanent_category',
    'sub_category',
    'avg_lease_rate',
  ],
  mall_Tentant_Lease: {
    column: [
      'retail_property_type',
      'category',
      'sub_category',
      'avg_lease_rate',
    ],
    data: [
      'property_type',
      'tanent_category',
      'sub_category',
      'avg_lease_rate',
    ],
  },
  retailFandB: ['property_name', 'unit_subtype', 'lease_rate'],
  retail_FandB: {
    column: ['retail_property_type', 'unit_subtype', 'lease_rate'],
    data: ['property_name', 'unit_subtype', 'lease_rate'],
  },
  retailFandBOcupied: ['tenant_name', 'property_name', 'cuisine'],
  retail_FandB_Ocupied: {
    column: ['tenant_name', 'property_name', 'cuisine'],
    data: ['tenant_name', 'property_name', 'cuisine'],
  },
  landVacancyTotal: {
    column: ['parcel_type', 'no_of_parcel', 'area_of_parcel'],
    data: ['parcel_type', 'no_of_parcel', 'area_of_parcel'],
  },
  employmentData: ['profession'],
  basicColumn: ['neigh_name', 'Category'],
};

export const reportTableColumn = {
  unitInventory_propertyGrade: {
    column: ['neigh_name', 'grade_wise'],
    data: ['neigh_name', 'category'],
  },
  unitInventory_unitTypeWise: {
    column: ['neigh_name', 'unit_type'],
    data: ['neigh_name', 'category'],
  },
  unitInventory_gradeWise: {
    column: ['neigh_name', 'grade_wise'],
    data: ['neigh_name', 'category'],
  },
  unit_numberOfUnits: {
    column: ['neigh_name', 'unit_type'],
    data: ['neigh_name', 'category'],
  },
  unitOccupancy_leaseAndOcc: {
    column: ['neigh_name', 'unit_type'],
    data: ['neigh_name', 'category'],
  },
  population_AgeWise: {
    column: ['neigh_name', 'age'],
    data: ['neigh_name', 'age_group'],
  },
  land_master: {
    column: ['land_use', 'master_plan'],
    data: ['land_use', 'master_plan'],
  },
  MallInventory_neighTrendOcc: {
    column: ['neigh_name'],
    data: ['neigh_name'],
  },
  officeOccupancy_neighTrendOcc: {
    column: ['neigh_name'],
    data: ['neigh_name'],
  },
  officePropertyGrade: {
    column: ['area_range'],
    data: ['area_range'],
  },
  officeSpace_neighLease: {
    column: ['neigh_name'],
    data: ['neigh_name'],
  },
  mallProfiling_floorwise: {
    column: [
      'floors',
      'gross_leasable_area',
      'no_of_tenants',
      'total_anchor_tenant',
    ],
    data: [
      'floors',
      'gross_leasable_area',
      'no_of_tenants',
      'total_anchor_tenant',
    ],
  },
  employmentData: {
    column: ['profession'],
    data: ['profession'],
  },
  // road Data Report
  roadDataNeigBlockAreaReport: {
    column: ['neigh_name', 'block', 'road_category'],
    data: ['neighborhood', 'block', 'road_category'],
  },
  roadDataCategoryWiseChangeTypeInAreaReport: {
    column: ['road_category', 'neigh_name', 'block'],
    data: ['road_category', 'neighborhood', 'block'],
    colsAfterYearCol: ['change_type'],
  },
  roadDataCategoryWiseChangeTypeInLengthReport: {
    column: ['road_category', 'neigh_name', 'block'],
    data: ['road_category', 'neighborhood', 'block'],
    colsAfterYearCol: ['change_type'],
  },
  roadDataCategoryWiseChangeTypeInAreaTwoYear: {
    column: ['road_name', 'road_category', 'neigh_name', 'block'],
    data: ['road_name', 'road_category', 'neighborhood', 'block'],
    colsAfterYearCol: ['change_type'],
  },
  roadDataCategoryWiseChangeTypeInAreaMultiYear: {
    column: ['road_name', 'road_category', 'neigh_name', 'block'],
    data: ['road_name', 'road_category', 'neighborhood', 'block'],
    colsAfterYearCol: ['change_type'],
  },
  // Road Data Report End
  landVacancyLandUseCountReport: {
    column: ['zoning', 'neigh_name', 'block_no','vacant','developed','partially_developed','services','green','under_study','water'],
    data: ['zoning', 'neigh_name', 'block_no'],
  },
  landVacancyNeighBlockCountReport: {
    column: ['neigh_name', 'block_no', 'zoning','vacant','developed','partially_developed','services','green','under_study','water'],
    data: ['neigh_name', 'block_no', 'zoning'],
  },
  landVacancyLandUseAreaWiseReport: {
    column: ['zoning', 'neigh_name', 'block_no','vacant','developed','partially_developed','services','green','under_study','water'],
    data: ['zoning', 'neigh_name', 'block_no'],
  },
  landVacancyLandUseNeighBlockAreaWiseReport: {
    column: ['neigh_name', 'block_no', 'zoning','vacant','developed','partially_developed','services','green','under_study','water'],
    data: ['neigh_name', 'block_no', 'zoning'],
  },
  landVacancyLandUseDevlopedRatioReport: {
    column: ['zoning', 'neigh_name', 'block_no'],
    data: ['zoning', 'neigh_name', 'block_no'],
  },
  landVacancyNeighBlockRatioReport: {
    column: ['neigh_name', 'block_no', 'zoning'],
    data: ['neigh_name', 'block_no', 'zoning'],
  },
  propertyProfilingReport: {
    column: ['neigh_name', 'property_grade', 'parking_ratio'],
    data: ['neigh_name', 'property_grade', 'parking_ratio'],
  },
  unitFurnishingStatusProfilingReport: {
    column: [
      'neigh_name',
      'property_grade',
      'fully_furnished',
      'semi_furnished',
      'un_furnished',
      'grand_total',
    ],
    data: [
      'neigh_name',
      'property_grade',
      'fully_furnished',
      'semi_furnished',
      'un_furnished',
      'grand_total',
    ],
  },
  shareofamenities: {
    column: [
      'amenities',
      'property_grade',
      'selected_properties',
      'propertieswithamenities',
      'percentage_selected_properties',
    ],
    data: [
      'amenities',
      'property_grade',
      'total_properties',
      'amenities_property',
      'value',
    ],
  },
  neighbourhoodandblockunderconstructionproperty: {
    column: [
      'project_name',
      'neighborhood',
      'land_area',
      'built_up_area',
      'residential_area',
      'retail_area',
      'office_space_area',
      'industrial_area',
      'commercial_area',
      'parking_area',
      'anchor_tenants',
      'construction_start',
      'construction_end',
    ],
    data: [
      'project_name',
      'neighborhood',
      'land_area',
      'built_up_area',
      'residential_area',
      'retail_area',
      'office_space_area',
      'industrial_area',
      'commercial_area',
      'parking_area',
      'anchor_tenants',
      'construction_start',
      'construction_end',
    ],
  },
  officeProfiling: {
    column: ['property_name', 'floors', 'tenent_name', 'leasable_area'],
    data: ['property_name', 'floors', 'tenent_name', 'leasable_area'],
  },
};

export const common = {
  mapYear: [
    2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021,
  ],
  pieChartColor: [
    '#FF6881',
    '#80E4D4',
    '#67B5FF',
    '#92999F',
    '#B17341',
    '#107C41',
    '#5B9BD5',
    '#4472C4',
    '#ED7D31',
    '#70AD47',
    '#C6DFB4',
    '#4248f5',
    '#f5427b',
    '#17131c',
    '#9925be',
    '#5e32a8',
    '#c7c110',
    '#33320c',
    '#cccbb6',
    '#c70e17',
    '#a36f34',
    '#C6DFB4',
    '#FF6881',
    '#80E4D4',
    '#67B5FF',
    '#92999F',
    '#B17341',
    '#107C41',
    '#5B9BD5',
    '#4472C4',
    '#ED7D31',
    '#C6DFB4',
  ],
  barCharColor: [
    '#CEBFFF',
    '#FFEC9A',
    '#FFB689',
    '#9B63FF',
    '#A6F3C4',
    '#FFA8A8',
    '#7A7AFF',
    '#CEBFFF',
    '#FFEC9A',
    '#FFB689',
    '#9B63FF',
    '#A6F3C4',
    '#FFA8A8',
    '#7A7AFF',
    '#CEBFFF',
    '#FFEC9A',
    '#FFB689',
    '#9B63FF',
    '#A6F3C4',
    '#FFA8A8',
    '#7A7AFF',
  ],
};

export const filterApplicable = {
  unitOccupancy: {
    total: ['NEIGHBORHOODS', 'YEAR', 'AGE', , 'UNITTYPE', 'GRADE'],
    unitOccupancy_investment: [
      'NEIGHBORHOODS',
      'YEAR',
      'AGE',
      'UNITTYPE',
      'GRADE',
    ],
    unitOccupancy_occupancyYear: [
      'NEIGHBORHOODS',
      'YEAR',
      'AGE',
      'GRADE',
      'UNITTYPE',
    ],
    unitOccupancy_leaserate: [
      'NEIGHBORHOODS',
      'YEAR',
      'AGE',
      'UNITTYPE',
      'GRADE',
    ],
    unitOccupancy_occupancy: [
      'NEIGHBORHOODS',
      'YEAR',
      'AGE',
      'UNITTYPE',
      'GRADE',
    ],
  },
  unit: {
    total: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'UNITTYPE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_investment: [
      'NEIGHBORHOODS',
      'YEARS',
      'GRADE',
      'UNITTYPE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_unitType: [
      'NEIGHBORHOODS',
      'YEARS',
      'GRADE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_grade: [
      'NEIGHBORHOODS',
      'YEARS',
      'UNITTYPE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_furnishing: [
      'NEIGHBORHOODS',
      'YEARS',
      'GRADE',
      'UNITTYPE',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_occupancy: [
      'NEIGHBORHOODS',
      'YEARS',
      'GRADE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    unit_numberOfunits: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'UNITTYPE',
      'FURNISHINGSTATUS',
      'UNITSIZE',
      'LEASEPRICE',
    ],
  },
  inventory: {
    total: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'AGE',
      'UNITTYPE',
      'UNITSIZE',
      'MONTHLY_RENT',
    ],
    investment: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'AGE',
      'UNITTYPE',
      'UNITSIZE',
      'MONTHLY_RENT',
    ],
    residential: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'AGE',
      'UNITTYPE',
      'UNITSIZE',
      'MONTHLY_RENT',
    ],
    properties: ['NEIGHBORHOODS', 'YEAR', 'AGE', 'GRADE'],
    units: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'AGE',
      'UNITTYPE',
      'UNITSIZE',
      'MONTHLY_RENT',
    ],
    grade: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'AGE',
      'UNITSIZE',
      'MONTHLY_RENT',
    ],
  },
  propertyProfiling: {
    total: ['NEIGHBORHOODS', 'YEARS', 'GRADE', 'AGE', 'UNITAREA'],
    parking: ['NEIGHBORHOODS', 'YEARS', 'GRADE', 'AGE', 'UNITAREA'],
    parkingRatio: ['NEIGHBORHOODS', 'YEARS', 'AGE', 'UNITAREA', 'GRADE'],
    status: ['NEIGHBORHOODS', 'YEARS', 'AGE', 'UNITAREA', 'GRADE'],
    amenities: ['NEIGHBORHOODS', 'YEARS', 'AGE', 'UNITAREA', 'GRADE'],
  },
  population: {
    total: ['NEIGHBORHOODS', 'YEAR', 'NATIONALITY', 'GENDER', 'AGEGROUP'],
    year_wise: ['NEIGHBORHOODS', 'YEAR', 'NATIONALITY'],
    trendline: ['NEIGHBORHOODS', 'YEAR', 'NATIONALITY', 'AGEGROUP'],
    totalPop: ['NEIGHBORHOODS', 'YEAR', 'NATIONALITY', 'GENDER'],
  },
  mallProfling: {
    total: ['PROPERTY', 'YEAR'],
    floor_wise: ['PROPERTY', 'YEAR'],
  },
  mallInventory: {
    total: ['NEIGHBORHOODS', 'YEAR', 'PROPERTIES', 'UNITSIZE'],
    retailInventory: ['NEIGHBORHOODS', 'PROPERTIES', 'UNITSIZE'],
    occupancy: ['NEIGHBORHOODS', 'PROPERTIES', 'UNITSIZE'],
    incremental: ['NEIGHBORHOODS', 'PROPERTIES', 'UNITSIZE'],
    neighWise: ['NEIGHBORHOODS', 'YEAR', 'PROPERTIES'],
    occupiedArea: ['NEIGHBORHOODS', 'YEAR', 'PROPERTIES'],
  },
  landVacancy: {
    landVacancy: ['YEAR', 'NEIGHBORHOODS', 'BLOCKNO', 'ZONING'],
  },
  roadData: {
    total: ['YEAR', 'NEIGHBORHOODS&BLOCKS', 'ROADCATEGORY'],
    yearlyTrendOfRoadArea: ['YEAR', 'NEIGHBORHOODS&BLOCKS', 'ROADCATEGORY'],
    neighAndBlockWiseChangeinArea: [
      'YEAR',
      'NEIGHBORHOODS&BLOCKS',
      'ROADCATEGORY',
    ],
    roadCategoryWiseChangeTypeinArea: [
      'YEAR',
      'NEIGHBORHOODS&BLOCKS',
      'ROADCATEGORY',
      'CHANGETYPE',
    ],
    roadCategoryWiseChangeTypeinLength: [
      'YEAR',
      'NEIGHBORHOODS&BLOCKS',
      'ROADCATEGORY',
      'CHANGETYPE',
    ],
    roadNameWiseChangeTypeInAreaTwoYears: [
      'YEAR',
      'NEIGHBORHOODS&BLOCKS',
      'ROADCATEGORY',
      'ROADNAME',
      'CHANGETYPE',
    ],
    roadNameWiseChangeTypeInAreaMultiYear: [
      'YEAR',
      'NEIGHBORHOODS&BLOCKS',
      'ROADCATEGORY',
      'ROADNAME',
      'CHANGETYPE',
    ],
  },
  landmaster: {
    total: ['YEAR', 'MASTERPLAN', 'LANDUSE'],
  },
  tenantProfiling: {
    total: [
      'PROPERTY',
      'YEAR',
      'TENANTTYPE',
      'TENANTCATEGORY',
      'TENANTNAME',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    share: ['PROPERTY', 'YEAR', 'TENANTTYPE', 'UNITSIZE', 'LEASEPRICE'],
    trendSpace: [
      'PROPERTY',
      'YEAR',
      'TENANTTYPE',
      'TENANTCATEGORY',
      'TENANTNAME',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    leaseRate: [
      'YEAR',
      'TENANTTYPE',
      'TENANTCATEGORY',
      'TENANTNAME',
      'UNITSIZE',
      'LEASEPRICE',
    ],
  },
  officeOccupancy: {
    total: ['NEIGHBORHOODS', 'YEAR', 'GRADE', 'STATUS', 'TYPE', 'LEASEPRICE'],
    yearTrend: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'STATUS',
      'TYPE',
      'LEASEPRICE',
    ],
    occupiedArea: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'STATUS',
      'TYPE',
      'LEASEPRICE',
    ],
    incremental: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'STATUS',
      'TYPE',
      'LEASEPRICE',
    ],
    neighWise: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'STATUS',
      'TYPE',
      'LEASEPRICE',
    ],
    leaseRate: [
      'NEIGHBORHOODS',
      'YEAR',
      'GRADE',
      'STATUS',
      'TYPE',
      'LEASEPRICE',
    ],
    propertyGrade: ['NEIGHBORHOODS', 'GRADE', 'STATUS', 'TYPE', 'LEASEPRICE'],
  },
  officeSpace: {
    total: ['NEIGHBORHOODS', 'YEAR', 'GRADE', 'STATUS', 'TYPE'],
    yearTrend: ['NEIGHBORHOODS', 'YEAR', 'GRADE', 'STATUS', 'TYPE'],
    occupTrend: ['NEIGHBORHOODS', 'YEAR', 'GRADE', 'STATUS', 'TYPE'],
    neighWise: ['NEIGHBORHOODS', 'YEAR', 'GRADE', 'STATUS', 'TYPE'],
  },
  officeProfiling: {
    total: [
      'NEIGHBORHOODS',
      'YEAR',
      'PROPERTYNAME',
      'AGE',
      'GRADE',
      'STATUS',
      'TYPE',
    ],
    yearTrend: [
      'NEIGHBORHOODS',
      'YEAR',
      'PROPERTYNAME',
      'AGE',
      'GRADE',
      'STATUS',
      'TYPE',
    ],
    occupArea: [
      'NEIGHBORHOODS',
      'YEAR',
      'PROPERTYNAME',
      'AGE',
      'STATUS',
      'TYPE',
    ],
    retailYear: [
      'NEIGHBORHOODS',
      'YEAR',
      'PROPERTYNAME',
      'AGE',
      'GRADE',
      'STATUS',
    ],
    share: ['NEIGHBORHOODS', 'YEAR', 'PROPERTYNAME', 'AGE', 'GRADE', 'STATUS'],
    tenantWise: [
      'NEIGHBORHOODS',
      'YEAR',
      'PROPERTYNAME',
      'AGE',
      'GRADE',
      'STATUS',
    ],
  },
  EmploymentData: {
    total: ['NATIONALITY', 'YEAR', 'PROFESSION'],
    employment: ['NATIONALITY', 'YEAR', 'PROFESSION'],
    office_demand: ['NATIONALITY', 'YEAR', 'PROFESSION'],
    brakup_office_demand: ['NATIONALITY', 'YEAR', 'PROFESSION'],
    sector_wise_employment: ['NATIONALITY', 'YEAR', 'PROFESSION'],
  },
  retailFB: {
    total: [
      'NEIGHBORHOODS',
      'RETAILPROPERTY',
      'PROPERTY',
      'YEAR',
      'TENANTNAME',
      'SUBCATEGORY',
      'CUISINE',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    averageLease: [
      'NEIGHBORHOODS',
      'RETAILPROPERTY',
      'YEAR',
      'TENANTNAME',
      'SUBCATEGORY',
      'CUISINE',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    occupiedArea: [
      'NEIGHBORHOODS',
      'RETAILPROPERTY',
      'PROPERTY',
      'YEAR',
      'TENANTNAME',
      'SUBCATEGORY',
      'CUISINE',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    categoryWise: [
      'NEIGHBORHOODS',
      'RETAILPROPERTY',
      'PROPERTY',
      'YEAR',
      'TENANTNAME',
      'CUISINE',
      'UNITSIZE',
      'LEASEPRICE',
    ],
    cuisineWise: [
      'NEIGHBORHOODS',
      'RETAILPROPERTY',
      'PROPERTY',
      'YEAR',
      'TENANTNAME',
      'SUBCATEGORY',
      'UNITSIZE',
      'LEASEPRICE',
    ],
  },
  underConstruction: {
    total: ['YEAR', 'NEIGHBORHOODSANDBLOCK'],
    neighborhoodAndBlock: ['YEAR', 'NEIGHBORHOODSANDBLOCK'],
  },
};

export const general = {
  defaultLang: {
    language_code: 'en',
    language_id: 1,
    name: 'English-US',
    status: 1,
  },
  chartType: {
    totalReport: 0,
    tableView: 1,
    barChart: 2,
    pieChart: 3,
    lineChart: 4,
    pieChartSolidGauge: 5,
    employmentTableView: 6,
    roadTableView: 7,
    barLineGroupChart: 8,
    normalBarLineGroupChart: 9,
    barStackedChart: 10,
    multiBarLineGroupChart: 11,
    barGroupChart: 12,
    landVacancyView: 13
  },
  language: {
    1: {
      language_code: 'en',
      language_id: 1,
      name: 'English-US',
      status: 1,
    },
    2: {
      language_code: 'ar',
      language_id: 2,
      name: 'Arabic',
      status: 1,
    },
  },
};
