import { EventEmitter, Injectable } from '@angular/core';
import { apiEndPoints } from '../../../shared/api-endpoint';
import { Observable, throwError, Subject, BehaviorSubject } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { BrowserService } from 'src/app/services/browser.service';
import { subscriptionUrl } from "../../subscription/subscription_url";

@Injectable({
  providedIn: 'root',
})
export class ReportsService {
  landVacancyTotalToMap = new BehaviorSubject([]);
  reportEndPoint: any = apiEndPoints.reportsEndPoints;
  currentStep = new Subject<number>();
  reportslist = {};
  filterValue = new Subject<any>();
  filterFlag:boolean=false;
  polygon: any = new BehaviorSubject<any>('');
  public objectdetails = new BehaviorSubject<any>('');
  public groupsDetails = new BehaviorSubject<any>('');
  public reportDetails = new BehaviorSubject<any>('');
  public cancelBtn = new BehaviorSubject<any>('');
  language_id: string = '';
  mapObject: any;
  landVacancyMapObject: any;
  popupFilterObject: any;
  clearMapEvent:Subject<void>=new Subject<void>();
  clearFiltered = new Subject<boolean>();
  propertyData = new Subject<any>();
  viewMapMallProfiling = new Subject<boolean>();
  selectedReportToGenerate = new BehaviorSubject<any>('');
  mapBounds= new BehaviorSubject<any>('');
  callNotifications=new BehaviorSubject<any>('');
  reportName = new BehaviorSubject<any>('');
  masterPlan = new BehaviorSubject<any>('');
  quarterFilterValue = {};
  neighBlockFilterValue = {};
  neighBlockappliedValue = [];
  checkSubscription = new Subject<boolean>();

  // subsVar: Subscription;    

  filteData = new BehaviorSubject<any>([]);
  setColorForCategory = new Map();


  constructor(private apiService: ApiService, private browser: BrowserService) {
    this.userSubscriptions();
    this.language_id = this.apiService.language ? this.apiService.language.toString() : ''
    this.apiService.getMiData(`${this.reportEndPoint.searchMaster}`).subscribe((data: any) => {
      if (data.data) {
        this.browser.setLocalValue('master_filter', JSON.stringify(data.data));
      }
    })
  }

  setStep(step: number) {
    this.currentStep.next(step);
  }

  getStep(): Observable<any> {
    return this.currentStep.asObservable();
  }



  setReportList(menu: string, data) {
    this.reportslist[menu] = data;
  }

  getReportList(menu: string) {
    if (this.reportslist[menu]) {
      return this.reportslist[menu];
    }
    return false;
  }



  redirectUrl(step, city) {
    switch (step) {
      case 0:
        return `${city}/reports/report-group/objectives`;
      case 1:
        const data = this.getReportList('objective');
        return `${city}/reports/report-group/sectoral-study?oid=${data.id}`;
      case 2:
        const data1 = this.getReportList('objective');
        const group = this.getReportList('group');
        return `${city}/reports/report-group/view-queries?oid=${data1.id}&gid=${group.id}`;
    }
  }

  setFiltervalue(data) {
    let url = {};
    let selectedReport: any;
    if (this.browser.getLocalValue('selected_report')) {
      selectedReport = JSON.parse(this.browser.getLocalValue('selected_report'));
    }
    let master_f: any;
    if(selectedReport.filter){
      for (let key in data) {
        master_f = selectedReport.filter.filter(ele => key == ele.parameter_name);
        master_f = master_f[0];
        if (master_f) {
          if (master_f.type == 'range') {
            const value = data[key].split('-');
            url[key] = {
              min: value[0],
              max: value[1],
            };
          } else {
            url[key] = data[key];
          }
        }
      }
  
    }
    
    return url;
  }

  getFilterInURL(data) {
    let report_filter: any;
    if (this.browser.getLocalValue('selected_report')) {
      report_filter = JSON.parse(this.browser.getLocalValue('selected_report'));
    }
    let url = {};
    let master_f: any;
    for (let key in data) {
      if (data[key]) {
        master_f = report_filter?.filter.filter(ele => key == ele.parameter_name);
             
        if (master_f && master_f.length && master_f[0]) {
          master_f = master_f[0];
          if (master_f.type == 'range') {
            let min=0, max=0;
            if(data[key].min == null || data[key].min == '' || data[key].min == undefined){
            }else{ 
              min = data[key].min;
            }
            if (data[key].max == null || data[key].max == '' || data[key].max == undefined) {
            }else{
              max = data[key].max;
            }           
            url[key] = `${min}-${max}`;
          } else if (master_f.type == 'single_select' || master_f.type == 'text') {
            if (data[key]) {
              url[key] = data[key];
            }
          } else if (master_f.type == 'multi_select' && data[key]) {
            if( key == "year" && report_filter?.query?.component[0] == 'employment-data'){
              url[key] = data[key];
            }else{
              url[key] = data[key].join();
            }
           
          }
        }
      }
    }
    return url;
  }


  userSubscriptions() {
    let details: any = []
    let url = `${subscriptionUrl.url.user_subscription}?customer_id=${this.apiService.user_id}&user_id=${this.apiService.user_id}&sort_asc=false&is_count=true&status=1`;
    this.apiService.getMiData(url)
      .subscribe(
        (res: any) => {
          if (res && res.data) {
            if (res.data.length > 1) {
              res.data.map(ele => {
                if (ele.access_details) {
                  ele.access_details.map(e => {
                    if (details.length == 0) {
                      details.push(e)
                    }
                    else {
                      let index = details.findIndex(ele => ele.report_id == e.report_id);
                      if (index === -1) {
                        details.push(e)
                      }
                    }
                  })
                  this.apiService.userSubscription = details;
                  this.checkSubscription.next(details);
                }
              })
            }
            else {
              this.apiService.userSubscription = res.data;
              this.checkSubscription.next(details);

            }
          }
        },
        (err) => {
        }
      );
  }

  setPropertyData(data){
    this.propertyData.next(data);
  }

  viewMapProfiling(value){
    this.viewMapMallProfiling.next(value);
  }

  clearFilterData(value){
    this.clearFiltered.next(value);
  }


  setQuarterFilterValue(checked, year, value){
    if(checked){
      if(this.quarterFilterValue[year]){
        this.quarterFilterValue[year] = [...this.quarterFilterValue[year],...[value]];
      } else {
        this.quarterFilterValue[year] = [value];
      }
    } else {
      if(this.quarterFilterValue[year].length === 1){
        delete this.quarterFilterValue[year];
      } else {
        const ind = this.quarterFilterValue[year].indexOf(value);
        if(ind > -1){
          this.quarterFilterValue[year].splice(ind, 1);
        }
      }
    }
   
  }

  setQuarterFilterValueSingle(checked, year, value){
    if(checked){
      if(this.quarterFilterValue && this.quarterFilterValue[year]){
        if(this.quarterFilterValue[year].indexOf(value) == -1){
          this.quarterFilterValue[year] = [...this.quarterFilterValue[year],...[value]];
        }       
      } else {
        this.quarterFilterValue={};
        this.quarterFilterValue[year] = [value];
      }
    } else {
      if(this.quarterFilterValue && this.quarterFilterValue[year].length === 1){
        delete this.quarterFilterValue[year];
      } else {
        const ind = this.quarterFilterValue[year].indexOf(value);
        if(ind > -1){
          this.quarterFilterValue[year].splice(ind, 1);
        }
      }
    }
    
  }

  setNeighBlockFilterValue(value){
    let obj = {
      block_uid: [],
      neigh_name: []
    }
    value.forEach(d => {
      obj.neigh_name.push(d[0]);
      obj.block_uid.push(d[1]);
    })
    let newObj = {
      block_uid: obj.block_uid.join(','),
      neigh_name: obj.neigh_name.join(',')
    }
   this.neighBlockFilterValue = newObj;
  }

  setMainQuarterValue(value){
    this.quarterFilterValue = value;
  }

  getQuarterFilterValue(year){
    if(this.quarterFilterValue[year]){
      return this.quarterFilterValue[year].join(',');
    }
    return null;
  }

  clearQuarterFilter(){
    this.quarterFilterValue = {};
  }
 
}
