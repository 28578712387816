
<app-navbar></app-navbar>
<nav class="navbar header-nav-two navbar-expand-lg fixed-top w-100 justify-content-between">
    <div class="navbar-header text_right_ar">
        <div class="top_nav_burgermenu">
            <button type="button" class="navbar-toggler btn_hamburger" data-toggle="collapse" data-target="#menuicon"
                aria-controls="menuicon" aria-expanded="false" aria-label="Toggle navigation">
                <input _ngcontent-serverapp-c97="" type="checkbox" />
                <span></span>
                <span></span>
                <span></span>
            </button>
            <div class="btn_requestfordemo">
                <button type="button" *ngIf="!userId" [routerLink]="['/request-demo']"
                    routerLinkActive="router-link-active" class="btn header-demo lean-radius"
                    [disabled]="demodisable ? true : null">{{'header.request_for_demo' | translate}}</button>
                <button [ngStyle]="selectedLangCode=='ar' ? {'margin-left': '5%'} : {}" type="button" *ngIf="userId"
                    [routerLink]="['/reports']" routerLinkActive="router-link-active"
                    class="btn header-demo lean-radius">{{'header.view_reports' | translate}}</button>
            </div>
        </div>
        <div class="collapse navbar-collapse" id="menuicon">
            <ng-container *ngIf="!mobileLoginError">
                <ng-container *ngIf="!mobileLoginError">
                    <div class="mob-login lean-radius text-center" data-target="#menuicon" data-toggle="collapse">
                        <a class="nav-link" [routerLink]="['/']">
                            <img src="../../../assets/MIAssets/user_no_login.svg" class="img-fluid" />
                            &nbsp;{{'User.Login' | translate}}
                        </a>
                    </div>
                </ng-container>
                <ul class="nav navbar-nav header-nav-links" data-target="#menuicon" data-toggle="collapse">
                    <li *ngFor="let page of staticPages">
                        <a *ngIf="page.title!='news_blog'" [routerLink]="page.routerLink" routerLinkActive="pageActive"
                            [routerLinkActiveOptions]="{ exact: true }" class="nav-link" href=""
                            (click)="slectedPage(page.title)">
                            {{'header.'+page.title | translate}}
                        </a>
                        <a *ngIf="page.title=='news_blog'" class="nav-link" href="https://blog.estater.com"
                            target="blank_">
                            {{'header.'+page.title | translate}}
                        </a>
                    </li>
                </ul>
            </ng-container>
            <ng-container *ngIf="mobileLoginError">
            </ng-container>
        </div>
    </div>
</nav>