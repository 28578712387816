import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { ApiService } from 'src/app/services/api.service';
import { BrowserService } from 'src/app/services/browser.service';
import { CommonfunctionService } from 'src/app/services/commonfunction.service';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';

import { actionIdConstants } from '../../shared/common.constants';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-share-report',
  templateUrl: './share-report.component.html',
  styleUrls: ['./share-report.component.scss'],
})
export class ShareReportComponent implements OnInit {
  Url = '';
  loader: boolean;
  error_messg: boolean;
  emails = [];
  removable = true;
  mailCtrl = new FormControl();
  separatorKeysCodes: number[] = [SPACE, COMMA, TAB, ENTER];
  constructor(
    private fb: FormBuilder,
    private api: ApiService,
    private fnc: CommonfunctionService,
    private browser: BrowserService,
    public dialogRef: MatDialogRef<ShareReportComponent>,
    private activatedRoute: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId: any,
    private alert: AlertService,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Url = this.data.pageUrl ? this.data.pageUrl : '';
  }

  ngOnInit(): void {
    if (this.Url.length == 0) {
      this.Url = this.fnc.removeQString(['srid']);
    } else {
      this.Url = this.data.pageUrl ? this.data.pageUrl : '';
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // Add our fruit
    if (value) { 
      if (this.fnc.validateEmail(value)) {
        this.emails.push(value);
      } else {
        this.emails.push(value);
        this.error_messg = true;
      }
    }
    // Clear the input value
    //event.chipInput!.clear();
    this.mailCtrl.setValue(null);
  }

  addFocusOut(event: any): void {
    const value = (event.target.value || '').trim();
    // Add our fruit
    if (value) {
      if (this.fnc.validateEmail(value)) {
        this.emails.push(value);
      } else {
        this.emails.push(value);

        this.error_messg = true;
      }
    }
    // Clear the input value
    //event.chipInput!.clear();
    this.mailCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.emails.indexOf(fruit);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    for (let i = 0; i < this.emails.length; i++) {
      const element = this.emails[i];
      if (
        this.fnc.validateEmail(
          element.primary_email_id ? element.primary_email_id : element
        ) == false
      ) {
        this.error_messg = true;
        break;
      } else {
        this.error_messg = false;
      }
    }
    if (this.emails.length == 0) this.error_messg = false;
  }
  close() {
    this.dialogRef.close(false);
  }
  shareReport() {
    this.error_messg = false;
    if (this.emails.length == 0) return;
    let User_info: any;
    if (this.browser.getLocalValue('user_info')) {
      User_info = JSON.parse(this.browser.getLocalValue('user_info'));
    }
    let body = {
      report_url: this.Url,
      from_email: User_info.email,
      to_email: this.emails.toString(),
      text: '',
    };
    this.useActivity();
    this.loader = true;
    this.api.postMiData(`report-share`, body).subscribe(
      (res: any) => {
        this.loader = false;
        if (res && res.status == 201) {
          this.alert.success(
            this.translate.instant('REPORTS.shared_successfully')
          );
          this.dialogRef.close();
        } else {
          this.alert.error(res.message);
        }
      },
      (err) => {
        this.loader = false;
      }
    );
  }
  copyTextToClipboard() {
    if (isPlatformBrowser(this.platformId)) {
      navigator.clipboard.writeText(this.Url).then(
        () => {
          this.alert.success('Content Copied');
        },
        function (err) {
          console.error('Async: Could not copy text: ', err);
        }
      );
    }
  }
  shareOnWhatsapp() {
    if (isPlatformBrowser(this.platformId)) {
      this.useActivity();
      let waUrl = `https://wa.me/?text=${encodeURIComponent(this.Url)}`;
      window.open(waUrl, '_blank');
    }
  }
  shareOnLinkedin() {
    this.useActivity();
    let waUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      this.Url
    )}`;
    window.open(waUrl, '_blank');
  }

  useActivity() {
    let User_info: any;
    if (this.browser.getLocalValue('user_info')) {
      User_info = JSON.parse(this.browser.getLocalValue('user_info'));
    }
    let body = {
      report_url: this.Url,
      from_email: User_info.email,
      to_email: this.emails.toString(),
      text: '',
    };
    let rid = this.activatedRoute.snapshot.paramMap.get('rid');
    let logData = {
      userdata: body,
      actionId: actionIdConstants.Share_Report,
      entityId: this.data?.report_id ? this.data.report_id:rid,
      entity: 'report',
    };
    this.api.logUserActivity(logData);
  }
}
