import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderSectionComponent } from './header-section/header-section.component';
import { MiHomeRoutingModule } from './mi-home-routing.module';
import { MiHomeComponent } from './mi-home.component';
import { FooterSectionComponent } from './footer-section/footer-section.component';
import { LoginComponent } from './login/login.component';
import { IntroComponent } from './intro/intro.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestDemoComponent } from './request-demo/request-demo.component';
import { LoaderModule } from '../shared/loader/loader.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ProductsComponent } from './products/products.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AboutComponent } from './about/about.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
  RecaptchaModule,
  RECAPTCHA_SETTINGS,
  RecaptchaSettings,
  RecaptchaFormsModule,
} from 'ng-recaptcha';
import { BrowserService } from '../services/browser.service';
import { I18nModule } from '../i18n/i18n.module';
import { MatModules } from '../mat-modules';
import { CoreModule } from '../core/core.module';
import { ShareModule } from '../core/share.module';
import { SignupComponent } from './signup/signup.component';
import { ResetPasswordComponent } from './login/reset-password/reset-password.component';
import { OtpInputComponent } from './login/reset-password/otp-input/otp-input.component';

@NgModule({
    declarations: [
        MiHomeComponent,
        LoginComponent,
        IntroComponent,
        HeaderSectionComponent,
        FooterSectionComponent,
        RequestDemoComponent,
        ProductsComponent,
        ContactUsComponent,
        AboutComponent,
        SignupComponent,
        ResetPasswordComponent,
        OtpInputComponent
    ],
    imports: [
        CoreModule,
        CommonModule,
        MiHomeRoutingModule,
        ...MatModules,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        LoaderModule,
        SlickCarouselModule,
        I18nModule,
        ShareModule,
    ],
    providers: [
        BrowserService,
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6Lc4aOIjAAAAAJG0LE_Oyd8Nn3pUEC_5ZIpwzeng'
            } as RecaptchaSettings
        }
    ],
    exports: [ResetPasswordComponent]
})
export class MiHomeModule {}
