<div class="form">
    <div class="mtc-header position-relation" fxLayout="row" fxLayoutAlign="center center">
        <div class="pt-3">
            <h3>{{'Profile.reset_password' | translate}}</h3>

        </div>
    </div>
    <div>
        <form [formGroup]="changePassForm" autocomplete="off" novalidate (ngSubmit)="changePassword(changePassForm)">
            <mat-dialog-content>
                <div class="pt-2 pl-2 pr-2 changefields">
                    <div class="input-group">
                        <span class="icon_envelope input_icon_rtl"></span>
                        <input id="password" type="password" class="form-control icon" formControlName="oldPass" placeholder="{{'Profile.old_pass_placeholder' | translate}}">
                       
                    </div>
                    <div *ngIf="submitted && f.oldPass.errors" class="invalid-error">
                        <div *ngIf="f.oldPass.errors.required">{{'signUp.oldpassword_is_required' | translate}}</div>
                    </div>
                    <div class="input-group">
                        <span class="icon_envelope input_icon_rtl"></span>
                        <input id="password" type="password" class="form-control icon" formControlName="newPass" placeholder="{{'Profile.new_pass_placeholder' | translate}}">
                       
                    </div>
                    <div *ngIf="submitted && f.newPass.errors" class="invalid-error">
                        <div *ngIf="f.newPass.errors.required">{{'signUp.password_is_required' | translate}}</div>
                        <div *ngIf="f.newPass.errors.invalidPassword">{{'signUp.password_pattern_not_match' | translate}}</div>
                    </div>
                    <div class="input-group">
                        <span class="icon_envelope input_icon_rtl"></span>
                        <input id="password" type="password" class="form-control icon" formControlName="rePass" placeholder="{{'Profile.re_pass_placeholder' | translate}}">
                       
                       
                    </div>
                    <div *ngIf="submitted && f.rePass.errors" class="invalid-error">
                        <div *ngIf="f.rePass.errors.required">{{'signUp.confirm_password_req' | translate}}</div>
                        <div *ngIf="f.rePass.errors.mustMatch">{{'signUp.confirm_password_match' | translate}}</div>
                    </div>
                </div>
            
               
            </mat-dialog-content>
            <div class="pass-pattern d-flex- justify-content-center align-items-center text-center">
                <span class="text-danger">*</span>
                <span>{{'Profile.password_pattern_not_match' | translate}} </span>
            </div>
            <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="dialog-actions reset_password">
                <button mat-raised-button class="blue-button"
                    >
                    {{'Profile.submit' | translate}}
                </button>
                <button type="button" class="btn_cancel" mat-raised-button (click)="cancel()">{{'Profile.cancel' | translate}}</button>
            </mat-dialog-actions>
        </form>
    </div>
</div>