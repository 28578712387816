<footer class="fixed-bottom">
    <!-- <div class="container-fluid">
        <a href="#">About</a>
        <a href="#" class="ab">Help</a>
        <a href="#" class="ab">Contact</a>
        <span class="copy">@2020 Remi Market Intelligence All Rights Reserved</span>

    </div> -->
    <div style="z-index: 1!important;" class="footer-copyright text-center fixed-bottom">
        <ul class="nav footer-nav social-grp float-left">
            <li>
                <a class="nav-link open-sans static-link" href="#">
                    About
                </a>
            </li>
            <li>
                <a class="nav-link open-sans static-link" href="#">
                    Help
                </a>
            </li>
            <li>
                <a class="nav-link open-sans static-link" href="#">
                    Contact
                </a>
            </li>
        </ul>
        <span class="copyright-text float-right open-sans">
            © {{year}} Remi Market Intelligence All Rights Reserved
        </span>
    </div>
</footer>