<div class="login-user text_right_ar">

    <div class="login_container">
        <app-loader *ngIf="loading"></app-loader>
        <div class="info-col">
            <div class="info-heading">
                <div class="mb-5">
                    <h1>{{'login.infoheading' | translate}}</h1>
                    <h4 class="f-14">{{'login.infocontent' | translate}}</h4>
                </div>
                <div class="login_banner_image">
                    <img class="img-fluid" src="../../../assets/MIAssets/Web-page/login_page.png" alt="Login" />
                </div>
            </div>
        </div>
        <div class="form-col">
            <ng-container *ngIf="showLogin;else signup">

            <div class="form_column">
                <div class="form-heading mb-4">
                    <h1 *ngIf="!forgot">{{'login.login' | translate}}</h1>
                    <h1 *ngIf="forgot">{{'login.reset_password' | translate}}</h1>
                </div>
                <form [formGroup]="LoginForm" (ngSubmit)="login(LoginForm)">
                    <ng-container *ngIf="!forgot">
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>{{'login.enter_your_email' | translate}}</mat-label>
                                <input type="email" matInput [class.is-invalid]="email.invalid && email.touched" autocomplete="address-line1" [class.border-red]="email.invalid && email.touched" formControlName="email" required />
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field class="example-full-width" appearance="fill">
                                <mat-label>{{'login.password' | translate}}</mat-label>
                                <input type="{{ showVar ? 'text' : 'password'}}" matInput [class.is-invalid]="password.invalid && password.touched" autocomplete="new-password" [class.border-red]="password.invalid && password.touched" formControlName="password" required />
                                <div class="eye_img" [class.eye_img_arb]="this.api.language==2">
                                    <img alt="eye closed" src="{{ showVar ? '../../../assets/MIAssets/icons/eye_open_grey.svg' : '../../../assets/MIAssets/icons/eye_closed.svg'}}" (click)="toggleEyeImage()" class="{{ showVar ? 'open' : 'close'}}" />
                                </div>
                            </mat-form-field>
                            <div style="height: 20px;margin-top: -15px;" *ngIf="password.valid || password.untouched">
                                <small [class.d-none]="password.valid || password.untouched" class="text-danger">{{'login.password_is_required' | translate}}</small>
                            </div>
                        </div>
                        <ng-container *ngIf="recaptchaActive">
                            <re-captcha formControlName="recaptchaReactive">
                            </re-captcha>
                        </ng-container>
                        <div class="form-group clearfix rem">
                            <label class=" float-left">
                                <div class="form-check">
                                    <mat-checkbox [(ngModel)]="rememberMe" [ngModelOptions]="{standalone:true}" class="example-margin form-check-input" id="exampleCheck1">
                                        {{'login.remember_me' | translate}}
                                    </mat-checkbox>
                                </div>
                            </label>
                            <div class="float-right">
                                <a href="JavaScript:void(0);" class="forget-link sans-seriff" (click)="jumpTo('forgot')">{{'login.forgot_password' | translate}}</a>
                            </div>
                        </div>
                        <div *ngIf="error" style="height: 20px;">
                            <span class="text-danger d-flex justify-content-center">{{error}}</span>
                        </div>

                        <div class="text-center">
                            <button type="submit" [disabled]="LoginForm.invalid || loading" class="btn submit mb-0 mt-2">{{'login.login' | translate}}</button>
                            <!-- <a href="Javascript:void(0)" class="btn w-50 submit_mob mb-0 mt-4"
                                (click)="login_mob()">{{'login.login' | translate}}</a> -->
                        </div>
                        <!-- <div class="text-center link_demorequest">

                        </div> -->
                    </ng-container>
                </form>
                <div *ngIf="forgot">
                  <app-reset-password (jump_to)="jumpTo($event)"></app-reset-password>
                </div>
                <!-- <form  [formGroup]="forgotPaasForm" (ngSubmit)="resetPassword(forgotPaasForm)">
                    <ng-container *ngIf="forgot">
                        <div class="form-group">
                            <input type="email" class="form-control input-background tb h45p" placeholder="Enter your email" autocomplete="address-line1" formControlName="email" required>
                        </div>
                        <div class="message" *ngIf="forgetMessage.m">
                            <span class="{{forgetMessage.class}}"> {{forgetMessage.m}} </span>
                        </div>
                        <div  fxLayout="row" fxLayoutAlign="space-around center">
                            <div class="">
                                <button fxLayout="row" fxLayoutAlign="center center" class="btn w-100 voilet mr-2" style="min-width: 120px;" color="primary" (click)="jumpTo('login')">
                                    <mat-icon class="mat-icons-sm mr-2">arrow_backward</mat-icon> {{'login.go_back' |
                                    translate}}
                                </button>
                            </div>
                            <div class="">
                                <button [disabled]="forgotPaasForm.invalid || loading" type="submit" style="min-width: 120px;" class="btn voilet w-100" color="primary">{{'login.reset' | translate}}
                                </button>
                            </div>
                        </div>
                    </ng-container>
                </form> -->

            </div>
        </ng-container>
        <ng-template #signup>
            <app-signup (changeLoginForm)="changeLoginForm($event)"></app-signup>
        </ng-template>
        <div class="switch-login">
            <span *ngIf="!showLogin" class="alreadyLogin" (click)="changeLogin()">Already registered ? Login <b>here</b></span>
            <span *ngIf="showLogin" class="alreadyLogin" (click)="changeLogin()">Not registered ? Sign Up <b>here</b></span>

        </div>
            <ng-container *ngIf="!forgot">
                <div class="reqest_demo">
                    <div class="form_column">
                        <p>{{'login.please_follow' | translate}}</p>
                        <a [routerLink]="['/request-demo']">{{'login.request_for_demo' | translate}}</a>
                    </div>
                </div>
            </ng-container>
        </div>

    </div>
</div>
