import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { BrowserService } from './browser.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private browser:BrowserService,
    private router: Router, 
    private api: ApiService) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      if(isPlatformBrowser(this.platformId)){
        if ((this.api.user_id && this.browser.getLocalValue('user_id'))) {
          return true;
        }else{
          this.api.logout(false);
          return false;
        };
      } else {
        return true;
      }
  }
}

@Injectable()
export class ReportAuthGuard implements CanLoad {
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private browser:BrowserService,
    private router: Router, 
    private api: ApiService) { }
  canLoad(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    if(isPlatformBrowser(this.platformId)){
      if (this.api.user_id && this.browser.getLocalValue('user_id') || this.browser.getCookie('_r_m_')) {
        return true;
      }else{
        let fullpath = window.location.href;
        let url = fullpath.replace(window.location.origin, '');
        this.api.logout(false, url.toString());
        return false;
      };
    } else {
      return true;
    }
  }
  
}