import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from "../../services/api.service";
import { CitySelectComponent } from '../city-select/city-select.component';
import { MatDialog } from '@angular/material/dialog';
import { SubjectService } from '../../services/subject.service';
import { GlobalService } from '../../services/global.service';
import { BrowserService } from '../../services/browser.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  public langList: any = []
  public profileImage: string = '';
  public userName: string;
  selectLang: any;
  cName: string;
  userCompanies: any = [];
  comName: string;
  userCompanyCount: number;
  dropDownEnable: boolean = false;
  selectedLangCode: any = 'en';
  comId: any;
  loginDisabled: boolean;
  user_id: number;
  citySlugUrl;

  assignedRoles: any = [];
  estater_meter = environment.estater_meter;
  about_MI = environment.about_MI;
  about_estater = environment.about_estater;

  ec_url = environment.ec_url;
  em_ang = environment.em_ang;
  cityList = environment.citis;
  noProfileName: string;

  constructor(@Inject(PLATFORM_ID) private platformId,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public api: ApiService,
    private dialog: MatDialog,
    private subject: SubjectService,
    private global: GlobalService,
    private browser: BrowserService,
    private router: Router,
    @Inject(DOCUMENT) private _document: Document) {
    this.subject.assignedRoles.subscribe(res => {
      this.assignedRoles = res;
    })
    this.iconRegistry.addSvgIcon('bell-icon', sanitizer.bypassSecurityTrustResourceUrl('../../../assets/MIAssets/bell_icon.svg'));
    if (this.global.getCurrentLangCode()) this.selectedLangCode = this.global.getCurrentLangCode();
    // this.userCompany();
    this.subject.selectedCompany.subscribe(company => {
      if (company) {
        this.comName = this.browser.getLocalValue('user_company');
        this.comId = '';
      }
    })

    if (this.browser.getLocalValue('company_id')) {
      this.comId = this.browser.getLocalValue('company_id');
    }
    //user name
    this.subject.userName.subscribe(uname => {
      if (uname) {
        this.userName = uname;
        this.user_id = this.api.user_id;
      } else {
        this.userName = null
      }
      // else {
      //   this.userName = JSON.parse(this.browser.getLocalValue('user_info')).fname + " " + JSON.parse(this.browser.getLocalValue('user_info')).lname;
      // }
    });
    //profile image
    // this.profileImage = this.browser.getLocalValue('profile_image');
    this.subject.profileImage.subscribe(pfImg => {
      // const img = new Image();
      const img = this._document.createElement('img'); ///for Prod IMAGE Undefined Fix
      img.src = pfImg;
      if (img.complete) {
        this.profileImage = pfImg;
        iconRegistry.addSvgIcon('prof_image', sanitizer.bypassSecurityTrustResourceUrl(pfImg));
      } else {
        img.onload = () => {
          this.profileImage = pfImg;
          iconRegistry.addSvgIcon('prof_image', sanitizer.bypassSecurityTrustResourceUrl(pfImg));
        };
        img.onerror = () => {
          this.profileImage = '';
        };
      }
    });
    this.subject.langList.subscribe(lang => {
      if (lang) {
        this.langList = lang;
      } else {
        if (this.browser.getLocalValue('lang_list')) {
          this.langList = this.browser.getLocalValue('lang_list');
        } else {
          this.langList = [];
        }
      }
    });
    this.langList = typeof this.langList == 'string' ? JSON.parse(this.langList) : this.langList;

    // //selected lang
    // this.subject.selectedLanguageForApp.subscribe(selectedLangForApp => {
    //  this.selectLang = this.browser.getLocalValue('selected_language')?JSON.parse(this.browser.getLocalValue('selected_language')).language_code:'';
    // })

    this.router.events.subscribe((url: any) => {
      if (url.url != undefined && url.url == '/login') {
        this.loginDisabled = true;
      }
      else {
        this.loginDisabled = false;
      }
    });
  }

  selectedLanguage(lng, reload: boolean = false) {
    this.browser.setLocalValue('language_id', lng.language_id);
    this.selectLang = lng.language_id;
    this.subject.selectedLanguageForApp.next(this.selectLang);
    this.global.changeLanguage(lng, reload);
    // window.location.reload();
  }


  logout(val) {
    this.api.logout(val);
    // setTimeout(() => {
    //   if (isPlatformBrowser(this.platformId)) {
    //     window.location.reload();
    //   }
    // }, 1500);
  }


  citySelect() {
    const dialogRef = this.dialog.open(CitySelectComponent, {
      width: "450px",
      panelClass: "city-dialog",
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  getCity() {
    if (this.browser.getLocalValue('city')) {
      this.cName = JSON.parse(this.browser.getLocalValue('city')).place_code;
    } else {
      this.cName = null;
    }
    return this.cName ? this.cName.toUpperCase() : null;
  }

  userCompany() {
    let url = `company-users?user_id=${this.api.user_id}`;
    this.api.getUmsData(url).subscribe((res: any) => {
      if (res.data && res.status == 200) {
        this.userCompanies = res.data;
        this.userCompanyCount = res.data.length;
        this.dropDownEnable = true;
      }
      else {
        this.dropDownEnable = false;
      }
    });
  }

  // selectedUserCompany(company) {
  //   if (company=="Public") {
  //     this.browser.setLocalValue('user_company',"Public");
  //     this.browser.setLocalValue('company_id', '');
  //   }
  //   else{
  //     this.browser.setLocalValue('user_company', company.company_name);
  //     this.browser.setLocalValue('company_id', company.company_id);
  //   }
  //   window.location.reload();
  // }


  getCompany() {
    if (this.browser.getLocalValue('user_company')) {
      this.comName = this.browser.getLocalValue('user_company');
    }
    return this.comName;
  }

  ngOnInit(): void {
    let userInfo = JSON.parse(this.browser.getLocalValue('user_info'));
    if(userInfo){
      let name = `${userInfo.fname} ${userInfo.lname}`;
      this.noProfileName = name.toUpperCase();
    }
    let city = JSON.parse(this.browser.getLocalValue('city'));
    if (city) {
      this.citySlugUrl = `/${city.slug}/reports`;
    } else {
      this.citySlugUrl = `/reports`;
    }
  }

}
