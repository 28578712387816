import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Page404Component } from './page-404';
import { ReportAuthGuard } from './services/auth.guard';
import { CityResolver } from './services/city-resolver.service';


const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./mi-fe/mi-home.module').then(m => m.MiHomeModule),
  },
  {
    path: ':cid/reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canLoad: [ReportAuthGuard],
    resolve: {
      city: CityResolver
    }
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
    canLoad: [ReportAuthGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user-dashboard/user-dashboard.module').then(m => m.UserDashboardModule),
    canLoad: [ReportAuthGuard]
  },
  {
    path: '**',
    component: Page404Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule],
  providers: [CityResolver]
})
export class AppRoutingModule { }
