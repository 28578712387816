import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  userProfile;

  constructor() { }


  setUserDetails(data){
    this.userProfile = data;
  }

  getUserProfile(){
    return this.userProfile;
  }
}
